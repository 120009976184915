import styled from 'styled-components';

import { CardsList, Text } from '@/styles';


export const Wrapper = styled.div``;

export const Content = styled.div<{ videoPage?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ videoPage }) => (videoPage ? 0 : '40px')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h1``;

export const CardList = styled(CardsList)``;

export const CardItem = styled.li`
  overflow-wrap: anywhere;
  cursor: pointer;
`;

export const VideoFileItem = styled.div`
  overflow-wrap: anywhere;
  cursor: pointer;
  position: relative;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const InfoTitle = styled(Text)`
  max-width: 560px;
  text-align: center;
  padding: 0 10px;
`;
