import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  width: max-content;

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 10px;
  min-width: 400px;
`;

export const ButtonsWrapper = styled.a`
  display: flex;
  width: max-content;
  gap: 10px;
  justify-self: flex-end;
  margin-top: 20px;
    
  > button {
      @media screen and (max-width: 768px) {
          padding: 0;
          flex: 1;
      }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: flex-end;
  }

  @media screen and (max-width: 478px) {
  }
`;

export const ButtonModalTextStyle = styled.span`
  font-size: 14px;
  color: white;
  line-height: 24px;
  font-weight: normal;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 540px;
`;

export const FooterAddVideo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
