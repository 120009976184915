import { ContainerStyled } from './Container.styled';

import { ReactNode } from 'react';

import { useIframe } from '@/hooks';
import { IframeThemeType } from '@/types/iframeTypes';

interface ContainerProps {
  libraryPage?: boolean;
  children?: ReactNode;
}

export const Container = ({ children, ...props }: ContainerProps) => {
  const { iframeTheme } = useIframe();
  return (
    <ContainerStyled {...props} iframeTheme={iframeTheme as IframeThemeType}>
      {children}
    </ContainerStyled>
  );
};
