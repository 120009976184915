import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  LinkDescription,
  TimecodesDescriptionText,
} from '@/pages/VideoPage/TimecodesTab/TimecodesDescription/TimecodesDescription.styled';
import { IframeThemeType } from '@/types/iframeTypes';

export const TimecodesDescription = ({
  text,
  isIframe,
  iframeTheme,
}: {
  text: string;
  isIframe?: boolean;
  iframeTheme?: IframeThemeType;
}) => {
  const { t } = useTranslation('components');
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  return (
    <div>
      <TimecodesDescriptionText isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        {isCollapsedText ? `${text.slice(0, 100)}...` : text}
      </TimecodesDescriptionText>
      <LinkDescription isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} onClick={onReadMoreClick}>
        {isCollapsedText ? t('timecodes_desc_more') : t('timecodes_button')}
      </LinkDescription>
    </div>
  );
};
