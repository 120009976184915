import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ArrowLeft from '@/components/SvgIcons/ArrowLeft';
import ArrowRight from '@/components/SvgIcons/ArrowRight';
import { useActions, useAppSelector } from '@/hooks';
import {
  AnswersWrapper,
  ButtonQuestionTab,
  ControlWrapper,
  IncorrectInfo,
  IncorrectInfoText1,
  IncorrectInfoText2,
  QuestionBlock,
  QuestionBody,
  QuestionHeader,
  QuestionTitle,
  QuestionWrapper,
} from '@/pages/VideoPage/QuizTab/QuestionPage/QuestionPage.styled';
import { ButtonTranscript } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import { IframeThemeType } from '@/types/iframeTypes';

interface QuestionProps {
  question: string;
  answers: string[];
  correctAnswer: string;
  answer: string | null;
  goToTime: (time: number) => void;
  start: number | string | undefined;
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
}

export const QuestionPage = ({
  isIframe,
  iframeTheme,
  answers,
  correctAnswer,
  answer: currentAnswer,
  question,
  goToTime,
  start,
}: QuestionProps) => {
  const { t } = useTranslation('components');
  const [selectedAnswer, setSelectedAnswer] = useState<'correct' | 'incorrect' | undefined>(undefined);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const { setQuizAnswer, setActiveQuestionIndex, setQuizDone } = useActions();
  const [activeQuestionIndex, questions] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
  ]);
  // const isLastQuestion = activeQuestionIndex === questions.length - 1;

  const handleAnswer = (answer: string) => {
    if (!isAnswerChecked) {
      setQuizAnswer({ question, answer });
    }
  };

  const onNextButtonHandler = () => {
    setIsAnswerChecked(false);
    setSelectedAnswer(undefined);

    if (!questions.filter((q) => q.answer === null).length) {
      setQuizDone();
    }

    if (questions.length > activeQuestionIndex + 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const checkAnswer = () => {
    if (selectedAnswer) {
      setIsAnswerChecked(true);
    }
  };

  return (
    <QuestionWrapper>
      <QuestionHeader>
        <QuestionTitle isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          {question}
        </QuestionTitle>
        <ControlWrapper>
          <ButtonTranscript
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            style={{ width: '85px' }}
            onClick={() => {
              setActiveQuestionIndex(activeQuestionIndex - 1);
            }}
            disabled={!activeQuestionIndex}
          >
            <ArrowLeft isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
          </ButtonTranscript>
          <ButtonTranscript
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            style={{ width: '85px' }}
            onClick={onNextButtonHandler}
          >
            <ArrowRight isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
          </ButtonTranscript>
        </ControlWrapper>
      </QuestionHeader>
      <QuestionBody>
        <AnswersWrapper style={{ width: '100%' }}>
          {answers.map((answer, index) => {
            const onChangeHandler = () => {
              setSelectedAnswer(
                currentAnswer === answer ? (answer === correctAnswer ? 'correct' : 'incorrect') : undefined,
              );
            };

            return (
              <QuestionBlock
                htmlFor={`answer-${index}`}
                key={index}
                isIframe={isIframe}
                iframeTheme={iframeTheme as IframeThemeType}
                answerType={
                  isAnswerChecked && currentAnswer === answer
                    ? answer === correctAnswer
                      ? 'correct'
                      : 'incorrect'
                    : undefined
                }
                disabled={!!currentAnswer}
                onClick={() => handleAnswer(answer)}
              >
                <input
                  onChange={onChangeHandler}
                  disabled={isAnswerChecked}
                  id={`answer-${index}`}
                  name="question-group"
                  type="radio"
                  checked={selectedAnswer && currentAnswer === answer}
                />
                {answer}
              </QuestionBlock>
            );
          })}
        </AnswersWrapper>
        {!isAnswerChecked ? (
          <ButtonQuestionTab onClick={checkAnswer} disabled={!selectedAnswer}>
            {t('question_button_video')}
          </ButtonQuestionTab>
        ) : (
          currentAnswer &&
          correctAnswer !== currentAnswer && (
            <>
              <IncorrectInfo>
                <IncorrectInfoText1>{t('incorrect_answer')}</IncorrectInfoText1>
                <IncorrectInfoText2>
                  {t('correct_answer')}: {correctAnswer}
                </IncorrectInfoText2>
              </IncorrectInfo>
              <ButtonQuestionTab onClick={() => goToTime(start as number)}>
                {t('question_watch_video')}
              </ButtonQuestionTab>
            </>
          )
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};
