import { AddAnswer, QuizVariantWrapper } from './QuizVariant.styled';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button } from '@/components/Shared/Button';
import { CheckboxItem } from '@/components/Shared/Checkbox/CheckboxItem';
import MyInput from '@/components/Shared/MyInput/MyInput';
import DeleteXIcon from '@/components/SvgIcons/DeleteXIcon';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';

import { QuizFormState } from '@/types';


export const QuizVariant = ({ questionIndex, index }: { questionIndex: number; index: number }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext<QuizFormState>();

  const { fields, remove, update, append } = useFieldArray<
    QuizFormState,
    `quizzes.${number}.quiz.${number}.answers`,
    'key'
  >({
    name: `quizzes.${index}.quiz.${questionIndex}.answers`,
    keyName: 'key',
    rules: {
      minLength: { value: 2, message: 'minLength' },
      validate: (value) => value.some(({ isCorrect }) => isCorrect),
    },
  });

  const handleToggleCorrect = (ind: number, isCorrect: boolean) => {
    if (!isCorrect) {
      fields.forEach(({ isCorrect: fieldIsCorrect }, i) => {
        if (i !== ind && fieldIsCorrect) {
          update(i, {
            answer: getValues(`quizzes.${index}.quiz.${questionIndex}.answers.${i}.answer`),
            isCorrect: false,
          });
        } else if (i === ind) {
          update(i, {
            answer: getValues(`quizzes.${index}.quiz.${questionIndex}.answers.${i}.answer`),
            isCorrect: true,
          });
        }
      });
      clearErrors(`quizzes.${index}.quiz.${questionIndex}.answers`);
    }
  };

  const handleRemoveAnswer = (value: number) => {
    remove(value);
  };

  const handleAddAnswer = () => {
    append({ answer: '', isCorrect: false });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {fields.map(({ key, isCorrect }, ind) => (
        <>
          <QuizVariantWrapper key={key}>
            <CheckboxItem checked={isCorrect} onCheckedChange={() => handleToggleCorrect(ind, isCorrect)} />
            <MyInput
              style={{
                overflow: 'hidden',
                border: 'none',
                outline: 'none',
                color: '#E4E4FF',
                borderRadius: '0px',
              }}
              placeholder=""
              {...register(`quizzes.${index}.quiz.${questionIndex}.answers.${ind}.answer`, { required: true })}
              error={errors.quizzes?.[index]?.quiz?.[questionIndex]?.answers?.[ind]?.answer}
              showErrorMessage={false}
            />
            <Button variant="withIcon" theme="transparent">
              <DeleteXIcon onClick={() => handleRemoveAnswer(ind)} />
            </Button>
          </QuizVariantWrapper>
        </>
      ))}
      <AddAnswer>
        <button onClick={handleAddAnswer}>
          <PlusBigIconBlack />
          <span>Добавить ответ</span>
        </button>
      </AddAnswer>
    </div>
  );
};
