import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #1a1145;
  border-radius: 8px;
  padding: 24px;
  margin-top: 30px;

  @media (max-width: 648px) {
    padding: 0;
  }
`;

export const BonusLabel = styled.div<{ bonus: number }>`
  background: linear-gradient(to right, #9333ea, #ec4899);
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  position: absolute;
  left: ${({ bonus }) => (bonus === 1 ? '15.7%' : bonus === 2 ? '36.2%' : bonus === 3 ? '67%' : 'auto')};
  bottom: 100%;

  @media (max-width: 648px) {
    left: ${({ bonus }) => (bonus === 1 ? '12.5%' : bonus === 2 ? '32.7%' : bonus === 3 ? '63%' : 'auto')};
  }
  @media (max-width: 526px) {
    left: ${({ bonus }) => (bonus === 1 ? '10.5%' : bonus === 2 ? '30.5%' : bonus === 3 ? '61%' : 'auto')};
  }
`;

export const Bonus = styled(motion.div)`
  padding-bottom: 30px;
`;

export const BonusIndicator = styled.div<{ bonus: number }>`
  width: 4px;
  height: 20px;
  background-color: #9333ea;
  position: absolute;
  left: ${({ bonus }) => (bonus === 1 ? '18%' : bonus === 2 ? '38.5%' : bonus === 3 ? '69.2%' : 'auto')};
`;

export const ValueLabel = styled.span`
  position: absolute;
  color: #d8b4fe;
  font-size: 16px;
`;

export const SliderContainer = styled(motion.div)`
  padding-left: 6%;
  padding-right: 6%;
`;

export const BonusWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const AmountLabels = styled.div`
  display: flex;
  font-size: 1rem;
  color: #d1d5db;
  margin-top: 2.5rem;
  position: relative;
  height: 1.5rem;

  @media (max-width: 648px) {
    position: initial;
  }
`;
