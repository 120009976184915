import { CardListPlaylist, Title, VideoListWrapper } from './IframePlayListPage.styled';

import FullScreenLoader from '../../components/Shared/FullScreenLoader/FullScreenLoader';
import { VideoList } from '../PlayListOutlet/components/VideoList/VideoList';

import { memo, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { InDev } from '@/App.styled';
import { VideoFragmentCard, VideoModal } from '@/components';
import { useAppSelector, useHandlingError, useIframeHeight } from '@/hooks';
import { useIframe } from '@/hooks/useIframe';
import { SearchInVideoInputV2 } from '@/pages/VideoPage/SearchInVideoInputV2';
import { IframeLangType, IframeThemeType } from '@/types/iframeTypes';

export const IframePlayListPage = () => {
  useIframeHeight();
  const { t, i18n } = useTranslation('components');
  const [isAuth] = useAppSelector((state) => [state.user.isAuth, state.user.user_id]);
  const [params] = useSearchParams();
  const { isIframe, iframeTheme, iframeLang, vendorId, showTest } = useIframe();
  const searchQueryValue = params.get('search');
  const { playlistId } = useParams();
  const { catchError } = useHandlingError();

  useEffect(() => {
    switch (iframeLang) {
      case IframeLangType.RU:
        i18n.changeLanguage('ru');
        break;
      case IframeLangType.EN:
        i18n.changeLanguage('en');
        break;
      default:
        i18n.changeLanguage('en');
    }
  }, [iframeLang]);

  const {
    data: playlist,
    isLoading,
    isError,
    error: playlistError,
  } = playlistsAPI.useGetPlaylistByIdQuery({ id: playlistId ?? '' });

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchInPlaylistQuery();

  useEffect(() => {
    catchError(playlistError || searchError);
  }, [playlistError, searchError]);

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlistId ?? '' });
    },
    [playlistId],
  );

  return (
    <div>
      {isLoading && <FullScreenLoader />}
      {isError && <InDev />}
      {!isError && playlist && (
        <>
          {(isAuth || isIframe) && (
            <SearchInVideoInputV2
              isIframe={isIframe}
              iframeTheme={iframeTheme as IframeThemeType}
              placeholder={t('search_in_pl', { ns: 'components' })}
              getSearch={getSearchVideosHandler}
            />
          )}
          <VideoListWrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {isSearchLoading && <FullScreenLoader />}
            {searchVideos &&
              searchQueryValue &&
              (searchVideos.length ? (
                <CardListPlaylist>
                  {searchVideos.map((fragment) =>
                    fragment.cues.map((cue, i) => (
                      <Link
                        to={`watch/${fragment.publicId}/?t=${cue.startsFrom}&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}`}
                        key={`${fragment.publicId}-${i}`}
                        state={{ playlistId: playlist.publicId, fromSearchPlaylist: true }}
                      >
                        <VideoFragmentCard
                          iframeTheme={iframeTheme as IframeThemeType}
                          isIframe={isIframe}
                          fragment={cue}
                          videoPreview={fragment.thumbnailUrl}
                        />
                      </Link>
                    )),
                  )}
                </CardListPlaylist>
              ) : (
                <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
                  {t('main.no_result', { ns: 'pages' })}
                </Title>
              ))}
            {!searchQueryValue && (
              <VideoList
                videos={playlist.videos}
                videoSettings={{ menu: true, isPrivate: playlist.privacyType === 'private' }}
              />
            )}
          </VideoListWrapper>
          <VideoModal />
        </>
      )}
    </div>
  );
};
export default memo(IframePlayListPage);

IframePlayListPage.displayName = 'IframePlayListPage';
