import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

export const ContainerStyled = styled.div<{ iframeTheme?: IframeThemeType; libraryPage?: boolean }>`
  width: 90%;
  max-width: 380px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
    
  @media screen and (max-width: 768px) {
    padding: 0;
    width: 95%;

    ${({ iframeTheme }) =>
      iframeTheme &&
      css`
        padding: 0;
      `}
  }

  @media (min-width: 480px) {
    max-width: 480px;
  }
  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    //max-width: 1024px;
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 100%;
  }
`;
