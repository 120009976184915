import styled, { css } from 'styled-components';

export const StyledAside = styled.aside<{ $isCollapsed: boolean }>`
  background: #0f0a2b;
  border-right: 1px solid rgba(168, 85, 247, 0.3);
  transition: all 300ms ease-in-out;
    
  @media screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
  }

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          width: 60px;

          @media screen and (max-width: 768px) {
          }
        `
      : css`
          width: 18rem;

          @media screen and (max-width: 768px) {
          }
        `}
`;

export const ContentContainer = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
    
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      align-items: center;
      padding: 16px 0;
    `}
`;

export const MainButton = styled.button<{ $isSelected: boolean; $isCollapsed: boolean }>`
  text-align: left;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  margin-bottom: 1rem;
  //margin-top: 13%;
  margin-top: 0;
  //margin-top: 82%;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background: rgba(168, 85, 247, 0.2);
          color: rgb(216, 180, 254);
        `
      : css`
          color: rgba(255, 255, 255, 0.8);

          &:hover {
            background: rgba(255, 255, 255, 0.05);
            color: white;
          }
        `}

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          padding: 0.5rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css`
          padding: 16px;
          width: 100%;
        `}
`;

export const ButtonContent = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      justify-content: center;
    `}
`;

export const PlaylistsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  gap: 0.5rem;
    overflow-y: scroll;
`;

export const PlaylistTitle = styled.h2<{ $isCollapsed: boolean }>`
  font-weight: 600;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        `
      : css`
          padding-left: 1rem;
          padding-top: 9px;
          padding-bottom: 5px;
        `}
`;

export const PlaylistList = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const PlaylistItem = styled.div<{ $isSelected: boolean; $isCollapsed: boolean }>`
  display: flex;
  //gap: 5px;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background: rgba(168, 85, 247, 0.2);
          color: rgb(216, 180, 254);
        `
      : css`
          color: rgba(255, 255, 255, 0.8);

          &:hover {
            background: rgba(255, 255, 255, 0.05);
            color: white;
          }
        `}

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          padding: 0.5rem;
        `
      : css`
          padding: 8px 16px;
        `}
`;

export const PlaylistContent = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
  min-width: 0;
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      justify-content: center;
    `}
`;

export const PlaylistImage = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 0.25rem;
  overflow: hidden;
  flex-shrink: 0;
  background: rgba(168, 85, 247, 0.2);

  img {
    max-width: 100%;
    height: -webkit-fill-available;
  }
`;

export const PlaylistName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;

export const ActionButtons = styled.div`
  display: none;
  gap: 0;

  button {
    padding: 0.25rem;
    color: rgba(255, 255, 255, 0.6);
    transition: color 0.2s;
  }

  button:nth-of-type(1) {
    &:hover {
      color: rgb(192 132 252);
    }
  }

  button:nth-of-type(2) {
    &:hover {
      color: rgb(244 114 182);
    }
  }

  ${PlaylistItem}:hover & {
    display: flex;
  }
`;

export const AddButton = styled.button<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  background-image: linear-gradient(to right, rgb(147, 51, 234), rgb(236, 72, 153));
  border-radius: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
    overflow: hidden;
  margin: 0 auto;

  &:hover {
    background-image: linear-gradient(to right, rgb(126, 34, 206), rgb(219, 39, 119));
  }

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          width: 2.5rem;
          height: 2.5rem;
        `
      : css`
          width: 100%;
          gap: 0.5rem;
          padding: 10px 16px;
        `}
`;

export const CollapseButton = styled.button<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  margin: 0 auto;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.05);
  }

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          width: 2.5rem;
          height: 2.5rem;
        `
      : css`
          width: 100%;
          padding: 0.5rem;
        `}
`;
