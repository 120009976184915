import { InfoBlockMaterials, TabItem, Tabs } from './VideoMaterialTabs.styled';

import { CSSProperties, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Captions from '@/components/SvgIcons/Captions';
import { Clock } from '@/components/SvgIcons/Clock';
import QuizIcon from '@/components/SvgIcons/QuizIcon';
import SummaryIcon from '@/components/SvgIcons/SummaryIcon';
import { useAppSelector, useMatchMedia } from '@/hooks';
import { InfoText } from '@/pages/AdminPage/AdminPage.styled';
import { IframeThemeType, Video } from '@/types';

export const VideoMaterialTabs = ({
  onTabChange,
  tab,
  isIframe,
  iframeTheme,
  video,
}: {
  onTabChange: (value: number) => void;
  tab: number;
  isIframe?: boolean;
  iframeTheme?: string;
  video: Video | undefined;
}) => {
  const { t } = useTranslation('components');
  const isTablet = useMatchMedia('(min-width: 1200px)');
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [showInfoTariff, setShowInfoTariff] = useState(false);

  const tabs = [
    { id: 1, status: video?.transcriptionStatus, icon: <Captions />, label: t('menu.transcript') },
    { id: 2, status: video?.timecodesStatus, icon: <Clock color={'currentColor'} />, label: t('menu.timecodes') },
    { id: 3, status: video?.summaryStatus, icon: <SummaryIcon />, label: t('menu.summary') },
    { id: 4, status: video?.quizzStatus, icon: <QuizIcon />, label: t('menu.quiz') },
  ];

  const onTabClickHandler = (tab: number) => {
    if (video?.transcriptionStatus !== 'NOT_ORDERED' && tab === 1) {
      onTabChange(1);
    }
    if (video?.timecodesStatus !== 'NOT_ORDERED' && tab === 2) {
      onTabChange(2);
    }
    if (video?.summaryStatus !== 'NOT_ORDERED' && tab === 3) {
      onTabChange(3);
    }

    if (video?.quizzStatus !== 'NOT_ORDERED' && tab === 4) {
      onTabChange(4);
    }
  };

  const getTabStyles = (isActive: boolean, isDisabled: boolean): CSSProperties => {
    const isLightTheme = isIframe && iframeTheme === IframeThemeType.light;

    return {
      opacity: isDisabled ? 0.5 : 1,
      backgroundColor: isLightTheme
        ? isActive
          ? 'rgb(228, 228, 255)'
          : 'transparent'
        : isActive
        ? 'rgb(59 42 126)'
        : 'rgb(26 17 69)',
      color: isLightTheme ? (isActive ? 'rgb(26 17 69)' : 'rgb(26 17 69)') : isActive ? 'white' : '#94A3B8',
      display: isIframe && isDisabled ? 'none' : 'flex',
    };
  };

  const onMouseOverHandler = (isDisabled: boolean) => {
    if (isDisabled) {
      setShowInfoTariff(true);
    }
  };

  return (
    <Tabs
      tabsCount={isIframe ? tabs.filter((tab) => tab.status !== 'NOT_ORDERED').length : tabs.length}
      isAuth={isAuth}
      isIframe={isIframe}
      iframeTheme={iframeTheme as IframeThemeType}
    >
      {showInfoTariff && (
        <InfoBlockMaterials isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          <InfoText
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            style={{ fontSize: '14px', lineHeight: '18px' }}
          >
            Эти материалы недоступны на вашем тарифе. Попробуйте тариф с расширенными возможностями, чтобы открыть
            таймкоды, конспекты и тесты.
          </InfoText>
        </InfoBlockMaterials>
      )}
      {tabs
        .filter((tab) => (isIframe ? tab.status !== 'NOT_ORDERED' : tab))
        .map((item) => (
          <TabItem
            key={item.id}
            onMouseOver={() => onMouseOverHandler(item.status === 'NOT_ORDERED')}
            onMouseOut={() => setShowInfoTariff(false)}
            onClick={() => onTabClickHandler(item.id)}
            style={getTabStyles(tab === item.id, item.status === 'NOT_ORDERED')}
          >
            {item.icon}
            {isTablet && item.label}
          </TabItem>
        ))}
    </Tabs>
  );
};
