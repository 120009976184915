import { Wrapper } from './EditTranscriptPage.styled';
import { TranscriptsForm } from './TranscriptsForm';

import { useParams } from 'react-router-dom';

import { useGetTranscriptsQuery } from '@/api';
import { FullScreenLoader } from '@/components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const EditTranscriptPage = () => {
  const { videoId } = useParams();

  const {
    data: transcripts,
    isLoading,
    isSuccess,
  } = useGetTranscriptsQuery(
    { videoId: videoId || '' },
    {
      skip: !videoId,
    },
  );

  if (!isSuccess && !isLoading) {
    return null;
  }

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {transcripts && (
        <Wrapper>
          <TranscriptsForm transcript={transcripts} />
        </Wrapper>
      )}
    </>
  );
};
