import './styles/customStyles.css';

import { Wrapper } from './EditSummaryPage.styled';
import { SummaryEditor } from './SummaryEditor';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetAllSummariesQuery, videosAPI } from '@/api';
import { FullScreenLoader } from '@/components';
import { useAppSelector } from '@/hooks';
import { Title } from '@/pages/AdminPage/AdminPage.styled';
import { Text } from '@/pages/VideoPage/TimecodesTab/TimecodesTab.styled';

export const EditSummaryPage = () => {
  const { videoId } = useParams();
  const { t } = useTranslation('loading');

  const isAuth = useAppSelector((state) => state.user.isAuth);
  const { data, isLoading } = useGetAllSummariesQuery({ videoId: videoId ?? '' }, { skip: !videoId });
  const { data: video } = videosAPI.useGetMovieByIdQuery({ id: videoId ?? '' }, { skip: !isAuth });

  if (!data || data?.results.length === 0) {
    return <Title>{t('load_summary')}</Title>;
  }

  if (video?.summaryStatus === 'NOT_ORDERED') {
    return <Text>Материал не был отправлен в обработку.</Text>;
  }

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {data.results.length > 0 && <SummaryEditor summary={data.results[0]} />}
    </Wrapper>
  );
};
