import {
  Buttons,
  TranscriptButtonWrapper,
  TranscriptFormWrapper,
  TranscriptWrapper,
} from './EditTranscriptPage.styled';
import { TitleTime } from './TitleAndTime';

import { useMemo } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePatchTranscriptsMutation } from '@/api';
import { Button, FullScreenLoader } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions, useHandlingError } from '@/hooks';
import { Transcript, TranscriptCueToForm } from '@/types';
import { formatDuration, parseDuration } from '@/utils';

export const TranscriptsForm = ({
  transcript,
  goToTime,
}: {
  transcript: Transcript;
  goToTime?: (time: number | string) => void;
}) => {
  const { videoId } = useParams();
  const { t } = useTranslation('pages');
  const { showNotification } = useActions();
  const navigate = useNavigate();
  const [updateTranscript, { isLoading: isUpdating, error }] = usePatchTranscriptsMutation();

  const { catchError } = useHandlingError();

  const { data, publicId } = transcript;

  const memoCues: TranscriptCueToForm[] = useMemo(
    () => data.cues.map((it) => ({ ...it, start: formatDuration(Math.round(it.start as number)) })),
    [data],
  );

  const methods = useForm<{ data: TranscriptCueToForm[] }>({ defaultValues: { data: memoCues } });
  const onFormSubmit = async ({ data }: { data: TranscriptCueToForm[] }) => {
    try {
      await updateTranscript({
        videoPk: videoId as string,
        publicId,
        body: {
          data: {
            cues: data.map((it) => ({ ...it, start: parseDuration(it.start) })),
          },
        },
      }).unwrap();

      showNotification({
        text: 'Изменения сохранены',
        severity: 'success',
      });
      navigate(`/videos/${videoId}`);
    } catch (e) {
      catchError(error, 'Ошибка! Изменения не сохранены');
    }
  };

  const { fields, insert, remove } = useFieldArray<{ data: TranscriptCueToForm[] }, 'data', 'key'>({
    control: methods.control,
    name: 'data',
    keyName: 'key',
  });

  const handleAddNewTranscript = (index: number) => {
    insert(index + 1, { start: '', text: '', durationMs: 0 });
  };

  return (
    <FormProvider {...methods}>
      <TranscriptFormWrapper onSubmit={methods.handleSubmit(onFormSubmit)}>
        <TranscriptButtonWrapper>
          {isUpdating && <FullScreenLoader />}
          <TranscriptWrapper>
            {fields.map((timecode, index) => (
              <>
                <TitleTime key={timecode.key} index={index} remove={() => remove(index)} goToTime={goToTime} />
                <Button
                  style={{
                    padding: '13px',
                    alignSelf: 'center',
                    backgroundColor: '#3B2A7E',
                    border: 'none',
                  }}
                  onClick={() => handleAddNewTranscript(index)}
                  disabled={isUpdating}
                >
                  <PlusBigIconBlack />
                </Button>
              </>
            ))}
          </TranscriptWrapper>
        </TranscriptButtonWrapper>
        <Buttons>
          <Button theme="pink" variant="pink" onClick={() => navigate(`/videos/${videoId}`)}>
            Отменить
          </Button>
          <Button
            width="100%"
            theme="colored"
            variant="colored"
            type="submit"
            disabled={
              isUpdating || transcript.data.cues.length === 0 || (methods.formState.errors.data?.length || 0) > 0
            }
          >
            {t('admin.save')}
          </Button>
        </Buttons>
      </TranscriptFormWrapper>
    </FormProvider>
  );
};
