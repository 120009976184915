import { ScrollBlock, Text } from '../TimecodesTab/TimecodesTab.styled';
import { ButtonTranscript, TitleTranscript, TitleTranscriptWrapper } from '../TranscriptVideo/TranscriptVideo.styled';
import { ContentWrap, VideoCardV2Wrapper } from '../VideoPage.styled';

import { CSSProperties } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { convertFromRaw, EditorState } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetDocsQuery, useLazyGetDocsQuery } from '@/api';
import { EditMaterialsButton } from '@/components';
import DownloadIcon from '@/components/SvgIcons/DownloadIcon';
import { useActions, useMatchMedia } from '@/hooks';
import { IframeThemeType, StatusType } from '@/types';
import './styles/HTMLSummaryStyles.css';

export const API_SHARE_URL = import.meta.env.VITE_BASE_URL;
export const API_SHARE_URL_VERSION = import.meta.env.VITE_BASE_URL_VERSION;

export const SummaryTab = ({
  hash,
  videoPublicId,
  summaryStatus,
  isIframe,
  iframeTheme,
}: {
  hash?: string;
  videoPublicId?: string;
  summaryStatus: StatusType | undefined;
  isIframe?: boolean;
  iframeTheme?: string;
}) => {
  const { t } = useTranslation('components');
  const isMobile = useMatchMedia('(max-width: 526px)');
  const { data, isLoading } = useGetDocsQuery({ videoPublicId: videoPublicId ?? '', hash });
  const { showNotification } = useActions();
  const navigate = useNavigate();
  const location = useLocation();
  const isLandingPage = location.pathname.includes('landing');
  const [getDocs] = useLazyGetDocsQuery();
  const getSummaryHandle = async () => {
    try {
      const summaryLink = await getDocs({
        videoPublicId: videoPublicId || '',
        hash,
      }).unwrap();

      const a = document.createElement('a');
      a.href = summaryLink.pdfFile;
      a.href = summaryLink.pdfFile.startsWith('http')
        ? summaryLink.pdfFile
        : `${API_SHARE_URL}/${API_SHARE_URL_VERSION}${summaryLink.pdfFile}`;
      a.target = '_blanc';
      // a.download = `${videoPublicId}.pdf`;

      a.click();
    } catch (e) {
      showNotification({ text: `Не получилось скачать конспект. Попробуйте чуть позже`, severity: 'error' });
    }
  };

  const customStyle: CSSProperties & { [key: string]: string } = {
    minHeight: '300px',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    '--theme-color': `${isIframe && iframeTheme === IframeThemeType.light ? '#000046' : '#D8B4FE'}`,
    '--theme-accept-color': `${isIframe && iframeTheme === IframeThemeType.light ? '#000046' : '#E4E4FF'}`,
  };

  if ((summaryStatus !== 'NOT_ORDERED' && summaryStatus !== 'DONE') || !data) {
    return (
      <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        Видео обрабатывается
      </Text>
    );
  }

  if (summaryStatus === 'NOT_ORDERED') return null;

  return (
    <VideoCardV2Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      <TitleTranscriptWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {t('title_summary')}
          </TitleTranscript>
          <ButtonTranscript
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            onClick={() => getSummaryHandle()}
          >
            <DownloadIcon />
            {!isMobile && t('download')} PDF
          </ButtonTranscript>
        </div>
        {!isIframe && !isLandingPage && (
          <EditMaterialsButton onClick={() => navigate(`/account/edit/${videoPublicId}/summary`)} />
        )}
      </TitleTranscriptWrapper>
      <ContentWrap isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        {isLoading && (
          <div style={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )}
        {summaryStatus === 'DONE' && data && (
          <ScrollBlock isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {data.htmlSummary ? (
              <div
                className="html-summary"
                dangerouslySetInnerHTML={{ __html: data.htmlSummary }}
                style={customStyle}
              />
            ) : (
              data.markdown && (
                <Editor
                  editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(data.markdown)))}
                  readOnly
                  toolbarHidden
                  editorStyle={customStyle}
                />
              )
            )}
          </ScrollBlock>
        )}
      </ContentWrap>
    </VideoCardV2Wrapper>
  );
};
