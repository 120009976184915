type PlayIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

const PlayIcon = ({ height, width, fill }: PlayIconProps) => (
  <svg
    width={width ? width : '69'}
    height={height ? height : '68'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.588 9.65869L8.1587 4.3147C6.24805 3.33567 4 4.75951 4 6.94867V17.0513C4 19.2405 6.24805 20.6643 8.1587 19.6853L18.588 14.3413C20.4707 13.3766 20.4707 10.6234 18.588 9.65869Z"
      stroke={fill ? fill : '#8d8dec'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlayIcon;
