import { TimecodesDescription } from './TimecodesDescription';
import {
  ScrollBlock,
  Text,
  Timecodes,
  TimecodesContentWrap,
  TimecodesTitleTab,
  TimeTitle,
  TitleTimecodesWrapper,
} from './TimecodesTab.styled';

import { getMinutes } from '../helpers';
import { TitleTranscript } from '../TranscriptVideo/TranscriptVideo.styled';
import { VideoCardV2Wrapper } from '../VideoPage.styled';

import { useEffect, useMemo, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetTimecodesQuery } from '@/api';
import { EditMaterialsButton } from '@/components';
import { FragmentTimeV2, ShareIconWrapper } from '@/components/Card/VideoFragmentCard/VideoFragmentCard.styled';
import { useHandlingError } from '@/hooks';
import { theme } from '@/styles';
import { IframeThemeType, StatusType } from '@/types';

interface TimecodesProps {
  setTime: (time: number) => void;
  id: string;
  currentTime?: number | null;
  hash?: string;
  timecodesStatus: StatusType | undefined;
  titleVideo: string;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const TimecodesTab = ({
  setTime,
  id,
  currentTime,
  hash,
  timecodesStatus,
  titleVideo,
  isIframe,
  iframeTheme,
}: TimecodesProps) => {
  // const { t } = useTranslation('components');
  const { catchError } = useHandlingError();

  const { data, isLoading, error } = useGetTimecodesQuery({ videoPublicId: id ?? '', hash });
  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const timecodeTitleRef = useRef<HTMLHeadingElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isLandingPage = location.pathname.includes('landing');

  const timings = useMemo(() => data?.map((array) => array.start as number) ?? [], [data]);

  useEffect(() => {
    if (listRef.current) {
      const children = Array.from(listRef.current.children) as HTMLLIElement[];
      children.forEach((child, index) => {
        if (currentTime && Math.round(currentTime) === timings[index]) {
          wrapperRef.current?.scrollTo({ top: child.offsetTop, behavior: 'smooth' });
        }

        if (
          currentTime &&
          currentTime >= timings[index] &&
          (timings[index + 1] === undefined || currentTime < timings[index + 1]) &&
          timecodeTitleRef.current
        ) {
          timecodeTitleRef.current.style.backgroundColor = '#BE9F4B';
        } else {
          timecodeTitleRef.current && (timecodeTitleRef.current.style.backgroundColor = 'transparent');
        }
      });
    }
  }, [currentTime]);

  if (timecodesStatus === 'NOT_STARTED') {
    return (
      <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        Видео обрабатывается
      </Text>
    );
  }

  const isTarget = (index: number) =>
    Boolean(
      currentTime &&
        currentTime >= timings[index] &&
        (timings[index + 1] === undefined || currentTime < timings[index + 1]),
    );

  return (
    <VideoCardV2Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} ref={wrapperRef}>
      {(timecodesStatus === 'IN_PROGRESS' || timecodesStatus === 'FAILED') && data && data.length === 0 && (
        <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          Видео обрабатывается
        </Text>
      )}
      {isLoading && (
        <div style={{ margin: '0 auto' }}>
          <CircularProgress color="secondary" size={40} />
        </div>
      )}
      {data && data.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: '1' }}>
          <TitleTimecodesWrapper>
            <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
              {titleVideo}
            </TitleTranscript>
            {!isIframe && !isLandingPage && (
              <EditMaterialsButton onClick={() => navigate(`/account/edit/${id}/timecodes`)} />
            )}
            {/*<ButtonTimecodesWrapper>*/}
            {/*  <ButtonTimecodes isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
            {/*    <Copy />*/}
            {/*    {!isMobile && t('copy_timecodes')}*/}
            {/*  </ButtonTimecodes>*/}
            {/*  <ButtonTimecodes isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>*/}
            {/*    <DownloadIcon />*/}
            {/*    {!isMobile && t('download')}*/}
            {/*  </ButtonTimecodes>*/}
            {/*</ButtonTimecodesWrapper>*/}
          </TitleTimecodesWrapper>
          <div style={{ position: 'relative', flex: '1' }}>
            <ScrollBlock style={{ inset: '0' }} isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
              <Timecodes ref={listRef}>
                {data.map(({ start, text, title }, index) => (
                  <TimecodesContentWrap
                    isIframe={isIframe}
                    iframeTheme={iframeTheme as IframeThemeType}
                    onClick={() => setTime(start as number)}
                    key={index}
                  >
                    <FragmentTimeV2
                      style={{
                        color: `${
                          isIframe && iframeTheme === IframeThemeType.light
                            ? theme.colors.blue.blue_dark
                            : 'rgb(192 132 252)'
                        }`,
                      }}
                    >
                      {getMinutes(start as number)}
                    </FragmentTimeV2>
                    <TimeTitle>
                      <TimecodesTitleTab
                        isIframe={isIframe}
                        iframeTheme={iframeTheme as IframeThemeType}
                        isTarget={isTarget(index)}
                      >
                        {title}
                      </TimecodesTitleTab>
                      <TimecodesDescription
                        isIframe={isIframe}
                        iframeTheme={iframeTheme as IframeThemeType}
                        text={text}
                      />
                    </TimeTitle>
                    <ShareIconWrapper style={{ maxHeight: 'max-content' }}>
                      {/*<Share*/}
                      {/*  color={isIframe && iframeTheme === IframeThemeType.light ? 'rgb(37 26 86)' : 'rgb(168 85 247)'}*/}
                      {/*/>*/}
                    </ShareIconWrapper>
                  </TimecodesContentWrap>
                ))}
              </Timecodes>
            </ScrollBlock>
          </div>
        </div>
      )}
    </VideoCardV2Wrapper>
  );
};
