// import { Text, TitleH3, CardsList } from '../../styles/components';

import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 16px;
  background: rgb(37, 26, 86);

  @media screen and (max-width: 768px) {
    padding: 8px 0;
    position: sticky;
    bottom: 0;
  }

  > button {
    padding: 0;
    flex: 1;
  }
`;

export const Wrapper = styled.div`
  padding: 16px;
  background-color: #251a56;
  max-width: 598px;
  width: 100%;
  max-height: 708px;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 8px;
    padding-bottom: 0;
  }
`;

export const TranscriptFormWrapper = styled.form``;

export const TranscriptButtonWrapper = styled.div`
  overflow-y: scroll;
  max-height: 555px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #1a1145;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #62548b;
  }
`;

export const TranscriptWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10px;
`;

export const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;
