import styled from 'styled-components';

export const QuizAnswersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
`;

export const QuizTitleEditWrapper = styled.div`
  border-bottom: 1px solid rgb(98, 84, 139);
`;

export const QuizBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;
