import { useEffect } from 'react';

export const useOutsideClick = (options: { ref: React.RefObject<HTMLElement>; handler: () => void }) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (options.ref.current && !options.ref.current.contains(event.target as Node)) {
        options.handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [options.ref, options.handler]);
};
