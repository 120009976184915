import styled, { css } from 'styled-components';

import { ButtonProps } from '@/components';
import { ButtonText, theme } from '@/styles';

export const ButtonStyled = styled.button<Pick<ButtonProps, 'variant' | 'width' | 'theme' | 'size'>>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => props.width};
  height: ${(props) => props.size};

  svg {
    display: block;
  }

  padding: ${(props) => (props.variant === 'withIcon' ? '10px' : '12px 30px')};

  @media screen and (max-width: 480px) {
    padding: ${(props) => (props.variant === 'withIcon' ? '2px' : '12px 30px')};
  }

  border-radius: 10px;
  border-width: 2px;
  border-style: solid;

  /* transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1); */

  ${(props) => {
    if (props.disabled && !(props.theme === 'colored' && props.variant === 'colored')) {
      return css`
        background-color: transparent;
        border-color: ${(props) => (props.theme === 'transparent' ? 'transparent' : theme.colors.white.white_40)};
        color: ${theme.colors.white.white_40};
        cursor: auto;

        & p,
        path {
          color: inherit;
          stroke: ${theme.colors.white.white_40};
        }
      `;
    }

    if (props.disabled && props.theme === 'colored' && props.variant === 'colored') {
      return css`
        background: linear-gradient(to left, #ec4899, #9333ea);
        border: none;
        border-radius: 8px;
        padding: 0 12px;
        height: 44px;
        opacity: 0.5;
      `;
    }

    switch (props.theme) {
      case 'white':
        return css`
          border-color: ${theme.colors.white.white_100};
          background-color: ${theme.colors.white.white_100};

          &:active {
            background-color: ${theme.colors.white.white_80};
          }

          & p {
            color: ${theme.colors.blue.blue_dark};
          }
        `;
      case 'red':
        return css`
          border-color: ${theme.colors.red.red_light};
          background-color: ${theme.colors.red.red_light};

          &:active {
            background-color: ${theme.colors.red.red_dark};
          }

          & p {
            color: ${theme.colors.blue.blue_dark};
          }
        `;
      case 'black':
        return css`
          border-color: ${theme.colors.blue.blue_20};
          background-color: ${theme.colors.blue.blue_20};

          &:active {
            background-color: ${theme.colors.blue.blue_5};
          }

          & p {
            color: ${theme.colors.white.white_100};
          }
        `;

      case 'transparent':
        return css`
          border-color: transparent;
          background-color: transparent;
          transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

          &:hover {
            scale: 1.01;
          }

          &:active {
            scale: 0.9;

            & p path,
            span {
              color: ${theme.colors.blue.blue_70};
              stroke: ${theme.colors.blue.blue_70};
            }
          }

          & p {
            color: ${theme.colors.white.white_100};
          }
        `;
      case 'colored':
        return css`
          background: linear-gradient(to left, #ec4899, #9333ea);
          border: none;
          border-radius: 8px;
          padding: 0 12px;
          height: 44px;

          &:hover {
            background: linear-gradient(to left, #db2777, #7e22ce);
            transition: all 0.2s;
          }
        `;
      case 'colored-default':
        return css`
          background-color: ${theme.colors.modal.black};
          border: 1px solid ${theme.colors.modal.purple_300};
          border-radius: 8px;
          color: ${theme.colors.modal.purple_500};
          padding: 0 12px;
          height: 44px;

          &:hover {
            transition: all 0.2s;
            color: white;
            background-color: transparent;
            border: 1px solid ${theme.colors.modal.purple_500};
          }
        `;
      case 'pink':
        return css`
          background-color: #3b2a7e;
          border-radius: 10px;
          border: none;
          color: #e4e4ff;
          //padding: 0 12px;
          height: 44px;

          &:hover {
            opacity: 0.8;
            //transition: all 0.2s;
            //background-color: transparent;
            //border: 1px solid ${theme.colors.modal.purple_500};
          }
        `;
      case 'inline':
        return css`
          border-color: ${theme.colors.white.white_100};
          background-color: transparent;

          &:active {
            border-color: ${theme.colors.white.white_40};
          }

          & p {
            color: ${theme.colors.white.white_100};
          }
        `;

      default:
        return css`
          border-color: transparent;
          background-color: transparent;

          &:active {
            color: ${theme.colors.blue.blue_70};

            & p {
              color: ${theme.colors.blue.blue_70};
            }
          }

          & p {
            color: ${theme.colors.white.white_100};
          }
        `;
    }
  }}
`;

export const ButtonInner = styled(ButtonText)``;
