import styled, { css } from 'styled-components';

import { TitleTranscript } from '@/pages/VideoPage/TranscriptVideo/TranscriptVideo.styled';
import { IframeThemeType } from '@/types/iframeTypes';

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex: 1;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 75%;
  justify-content: space-between;
  flex: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const QuestionHeader = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const QuestionBody = styled(QuestionHeader)``;

export const ControlWrapper = styled.div`
  display: flex;
  gap: 20px;
  max-height: 42px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const QuestionInfo = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  padding: 0 12px;
  background-color: rgb(37 26 86);
  border-radius: 25px;
  height: 24px;
  color: #d8b4fe;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 5px 8px;
    font-size: 12px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgb(228, 228, 255);
      color: #140150;
    `}
`;

export const ButtonQuestionTab = styled.button`
  align-items: center;
  background: linear-gradient(to right, #9333ea, #ec4899);
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
  height: 48px;
  color: white;
  border-radius: 10px;
  margin-top: 8px;
  cursor: pointer;

  @media screen and (max-width: 568px) {
    height: auto;
  }

  :hover {
    background: linear-gradient(to right, #6b21a8, #be185d);
  }

  :disabled {
    background: linear-gradient(to right, #9333ea, #ec4899);
    opacity: 0.5;
  }
`;

export const QuestionTitle = styled(TitleTranscript)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  line-height: 22px;
  font-weight: 700;
  white-space: normal;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 20px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26, 17, 69);
    `}
`;

export const IncorrectInfo = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.3);
  color: #f87171;
`;

export const IncorrectInfoText1 = styled.p`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 8px;
`;
export const IncorrectInfoText2 = styled(IncorrectInfoText1)`
  font-size: 14px;
  line-height: 1.25rem;
  margin-bottom: 0;
`;

export const QuestionBlock = styled.label<{
  answerType?: 'correct' | 'incorrect';
  iframeTheme: IframeThemeType;
  isIframe?: boolean;
  disabled: boolean;
}>`
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 16px;
  width: 100%;
  justify-content: start;
  margin-bottom: 0;
  background-color: rgb(37 26 86);
  cursor: pointer;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 480px) {
    padding: 8px;
  }

  ${({ answerType }) => {
    switch (answerType) {
      case 'correct':
        return css`
          border-color: rgb(34 197 94) !important;
          background-color: rgb(34 197 94 / 0.1) !important;
        `;
      case 'incorrect':
        return css`
          border-color: rgb(239 68 68) !important;
          background-color: rgb(239 68 68 / 0.1) !important;
        `;
      default:
        return css`
          border-color: rgb(168 85 247 / 0.3);
          background-color: transparent;
        `;
    }
  }}
  &:hover {
    transition: all 200ms ease-in-out;
    border-color: rgb(168 85 247);
    background-color: rgb(26 17 69);
  }

  &:disabled {
    cursor: auto;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: transparent;
      color: rgb(26 17 69);

      &:hover {
        background-color: rgb(228, 228, 255);
      }
    `}
`;
