import { InDev } from './App.styled';
import ScrollToTop from './components/ScrollToTop';
import FullScreenLoader from './components/Shared/FullScreenLoader/FullScreenLoader';
import AddMobile from './components/Shared/Modals/AddMobile/AddMobile';
import * as analytics from './ga4';
import RequireAuth from './hoc/RequireAuth';
import { EditQuizPage } from './pages/AdminPage/EditQuizPage';
import { EditSummaryPage } from './pages/AdminPage/EditSummaryPage';
import { EditTimecodesPage } from './pages/AdminPage/EditTimecodesPage';
import { EditTranscriptPage } from './pages/AdminPage/EditTranscriptPage';

import { Suspense, useEffect } from 'react';

import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import {
  AddVideo,
  CreatePlaylist,
  Login,
  Notification,
  Registration,
  RemoveVideo,
  RemoveVideoFile,
  ResendEmail,
  ResetPassword,
  VerifyInfo,
} from '@/components';
import { useActions, useAppSelector, useIframe } from '@/hooks';
import { MainLayout } from '@/layouts';
import {
  ActivateLazy,
  AdminVideoOutletLazy,
  BalancePage,
  LendingLazy,
  NotFoundPage,
  Password,
  PersonalDataPolicy,
  Playlist,
  PlayListOutlet,
  Policy,
  PrivacyPolicy,
  Profile,
  SuccessPaymentPage,
  TermsOfUse,
  VideoPage,
} from '@/pages';
import { LibraryPageV2 } from '@/pages/LibraryPageV2';
import { ModalType } from '@/store/slices/modalsSlice';
import Global from '@/styles/global';
import { IframeThemeType } from '@/types';

function App() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const { openModal } = useActions();
  const [/* onBoarding,*/ isAuth] = useAppSelector((state) => [/* state.user.onBoarding, */ state.user.isAuth]);
  const [params] = useSearchParams();
  const location = useLocation();
  const { isIframe, iframeTheme } = useIframe();

  useEffect(() => {
    if (!isAuth && params.has('popup') && !location.pathname.includes('embed')) {
      openModal({ active: params.get('popup') as ModalType });
    }
  }, [isAuth, params]);

  const setLayout = () => (location.pathname.includes('search') || !isAuth ? <Outlet /> : <MainLayout />);

  return (
    <>
      <ScrollToTop />
      {/* {(onBoarding === null || onBoarding === 'watch') && !isIframe && isAuth ? (
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<FullScreenLoader />}>
                <OnboardingLazy />
              </Suspense>
            }
          />
        </Routes>
      ) : ( */}
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="/" element={setLayout()} errorElement={<InDev />}>
            <Route
              element={
                <RequireAuth>
                  {/*<Library />*/}
                  <LibraryPageV2 />
                </RequireAuth>
              }
            >
              <Route index element={<LibraryPageV2 />} />
            </Route>

            <Route path="landing" element={<LendingLazy />} />

            <Route path="/balance" element={<BalancePage />} />
            <Route path="/billing-success/" element={<SuccessPaymentPage />} />

            <Route path="videos" element={<PlayListOutlet />}>
              <Route path=":id" element={<VideoPage />} />
            </Route>

            <Route path="password/reset/:uid/:token" element={<Password />} />

            <Route path="policy" element={<Policy />}>
              <Route path="personal" element={<PersonalDataPolicy />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="terms_of_use" element={<TermsOfUse />} />
            </Route>
            <Route path="activate/:uid/:token" element={<ActivateLazy />} />

            {/* required pages */}
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route index element={<Profile />} />
            </Route>

            <Route
              path="account"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route path="edit" element={<Outlet />}>
                <Route path=":videoId" element={<AdminVideoOutletLazy />}>
                  <Route path="transcript" element={<EditTranscriptPage />} />
                  <Route path="summary" element={<EditSummaryPage />} />
                  <Route path="timecodes" element={<EditTimecodesPage />} />
                  <Route path="quiz" element={<EditQuizPage />} />
                </Route>
              </Route>
              {/* </Route> */}
            </Route>

            {/* iframe pages */}
            <Route path="embed/videos/:id/" element={<VideoPage />} />
            <Route path="embed/playlist/:playlistId" element={<PlayListOutlet />}>
              <Route index element={<Playlist />} />
              <Route path="watch/:id" element={<VideoPage />} />
            </Route>

            <Route path="/*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
      {/* )} */}
      <Suspense>
        <Login />
        <Registration />
        <CreatePlaylist />
        <AddMobile />
        <AddVideo />
        <ResetPassword />
        <ResendEmail />
        <RemoveVideo />
        <RemoveVideoFile />
        <Notification />
        <Global isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />
        <VerifyInfo />
      </Suspense>
    </>
  );
}

export default App;

analytics.init();
analytics.sendGTAG();
