// import { useThrottle } from '@/hooks';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export const VkVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  { originLink: string; title: string; startsFrom?: number; height: string; setCurrentTime: (time: number) => void }
>(({ originLink, title, startsFrom = 0, height, setCurrentTime }, ref) => {
  const iframe = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        goToTime: (time: number) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const player = window.VK.VideoPlayer(iframe.current);
          player.seek(time);
          // TODO убрать после фикса переключения
          setCurrentTime(time);
        },
      };
    },
    [],
  );

  ///!TODO разобраться с переключением по времени
  // const onStateChange = (event: { state: string; time?: number }) => {
  //   console.log(event);
  //   if (event?.time) {
  //     setCurrentTime(event.time);
  //   }
  // };

  // const value = useThrottle(onStateChange, 1000);

  // useEffect(() => {
  //   if (iframe.current) {
  //     const player = window.VK.VideoPlayer(iframe.current);
  //     console.log(player);
  //     player.on('timeupdate', value);
  //   }

  //   () => window.VK.VideoPlayer(iframe.current).on('timeupdate', value);
  // }, []);

  return (
    <iframe
      ref={iframe}
      title={title}
      src={`${originLink}&hd=2&autoplay=14&t=${startsFrom}s&js_api=1`}
      width="100%"
      height={height}
      allow="encrypted-media; fullscreen; picture-in-picture;" //autoplay;
    ></iframe>
  );
});

VkVideoPlayer.displayName = 'VkVideoPlayer';
