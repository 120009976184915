import { CSSProperties, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';

import { InputWrapper } from '@/components/InputModal/InputModalStyle';

type InputProps = InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
  rows?: number;
  wrap?: 'hard' | 'soft' | 'off';
  sx?: CSSProperties;
};

const InputModal = forwardRef(
  ({ type = 'text', name, id, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
    return (
      <InputWrapper
        sx={props.sx}
        id={id || name}
        name={name}
        type={type}
        {...props}
        ref={ref as ForwardedRef<HTMLInputElement>}
      />
    );
  },
);

InputModal.displayName = 'InputModal';

export default InputModal;
