import styled from 'styled-components';

export const QuizVariantWrapper = styled.div`
  width: 100%;
  padding: 0 0 0 12px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(98, 84, 139);

  :last-child {
    border-bottom: none;
  }
`;

export const AddAnswer = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  button {
    width: 100%;
    height: 48px;
    padding: 12px 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    color: #62548b;

    gap: 4px;

    span {
      font-size: 17px;
      height: 48px;
      padding: 12px;
    }

    svg {
      width: 18px;
      height: 18px;
      path {
        stroke: #62548b;
      }
    }

    transition: all 0.2s ease-in-out;

    :hover {
      background-color: rgba(98, 84, 139, 0.1);
      scale: 1.01;
    }

    :active {
      background-color: rgba(98, 84, 139, 0.2);
      scale: 0.999;
    }
  }
`;
