import { Link } from 'react-router-dom';

import { FullScreenLoader, RemoveVideo, VideoFragmentCard } from '@/components';
import { CopyIframeButton } from '@/components/CopyIframeButton/CopyIframeButton';
import { AddButton } from '@/components/NavBar/NavBar.styled';
import { useActions, useIframe } from '@/hooks';
import VideoCardV2 from '@/pages/LibraryPageV2/VideoCardV2/VideoCardV2';
import { Container, NotVideoText, VideoGrid } from '@/pages/LibraryPageV2/VideoList/VideoList.styled';
import { IframeThemeType, Playlist, VideoInPlaylist, VideoWithFragments } from '@/types';

const VideoList = ({
  selectedPlaylistId,
  playlistsList,
  searchQueryValue,
  searchResult,
  searchVideosInPlaylist,
  searchVideos,
  filteredVideos,
  isLoading,
}: {
  playlistsList: Playlist[];
  selectedPlaylistId: string | null;
  searchQueryValue: string | null;
  searchResult: VideoWithFragments[] | VideoInPlaylist[];
  searchVideosInPlaylist: VideoWithFragments[];
  searchVideos: any;
  filteredVideos: VideoInPlaylist[];
  isLoading: boolean;
}) => {
  const { isIframe, iframeTheme, iframeLang, vendorId, showTest } = useIframe();
  const { openModal } = useActions();

  const renderSearchResults = () => {
    if (!searchQueryValue) {
      return filteredVideos?.map((video, i) => (
        <VideoCardV2
          key={i}
          selectedPlaylistId={selectedPlaylistId}
          playlistsList={playlistsList}
          movie={video}
          isIframe={isIframe}
          to={`videos/${video.publicId}?t=${video.startsFrom ?? 0}`}
        />
      ));
    }

    if (searchResult === searchVideosInPlaylist) {
      return (
        searchVideosInPlaylist &&
        searchVideosInPlaylist.map((fragment) =>
          fragment.cues.map((cue, i) =>
            isIframe ? (
              <Link
                to={`videos/${fragment.publicId}/?t=${cue.startsFrom}&vendor=${vendorId}&theme=${iframeTheme}&lang=${iframeLang}&show_test=${showTest}`}
                key={`${fragment.publicId}-${i}`}
                state={{ playlistId: selectedPlaylistId ?? '' }}
              >
                <VideoFragmentCard
                  iframeTheme={iframeTheme as IframeThemeType}
                  isIframe={isIframe}
                  fragment={cue}
                  videoPreview={fragment.thumbnailUrl}
                />
              </Link>
            ) : (
              <Link to={`videos/${fragment.publicId}/?t=${cue.startsFrom}`} key={`${fragment.publicId}-${i}`}>
                <VideoFragmentCard fragment={cue} videoPreview={fragment.thumbnailUrl} />
              </Link>
            ),
          ),
        )
      );
    }

    return searchVideos?.results.map((video: VideoInPlaylist) => (
      <VideoCardV2
        to={`videos/${video.publicId}?t=${video.startsFrom ?? 0}`}
        selectedPlaylistId={selectedPlaylistId}
        playlistsList={playlistsList}
        key={video.publicId}
        movie={video}
      />
    ));
  };

  if (!filteredVideos?.length && !isLoading) {
    return (
      <NotVideoText>
        {selectedPlaylistId ? (
          'Добавьте видео в плейлист'
        ) : (
          <AddButton
            onClick={() => openModal({ active: 'AddVideo' })}
            style={{ width: '250px', height: '40px' }}
            $isCollapsed={false}
          >
            <span style={{ fontSize: '16px', color: 'white' }}>Добавить видео</span>
          </AddButton>
        )}
      </NotVideoText>
    );
  }

  return (
    <Container>
      {isLoading && <FullScreenLoader />}
      {searchResult.length === 0 && searchQueryValue && !isLoading && (
        <NotVideoText>Результатов по запросу не найдено</NotVideoText>
      )}
      <VideoGrid>{renderSearchResults()}</VideoGrid>
      <div style={{ paddingTop: '40px', display: 'flex', justifyContent: 'flex-end' }}>
        {!searchQueryValue && selectedPlaylistId && playlistsList?.length > 0 && (
          <CopyIframeButton playlistId={selectedPlaylistId} />
        )}
      </div>
      <RemoveVideo />
    </Container>
  );
};

export default VideoList;
