import { AvatarProps } from './Avatar.props';
import { AvatarStyled } from './Avatar.styled';

import GuestIcon from '../../SvgIcons/GuestIcon';

const Avatar = ({ auth = false, url, name, size, style }: AvatarProps) => {
  const userTag = () => {
    if (name) {
      const [first, second] = name.split(' ');
      if (first && second) {
        return `${first.slice(0, 1).toUpperCase()}${second.slice(0, 1).toUpperCase()}`;
      } else {
        return `${first.slice(0, 1).toUpperCase()}`;
      }
    }
    return '';
  };
  console.log(url);

  return (
    <AvatarStyled size={size} url={auth ? url : undefined} style={style}>
      {auth ? userTag() : <GuestIcon />}
    </AvatarStyled>
  );
};

export default Avatar;
