import type { TFunction } from 'i18next';

export const generateBreadcrumbs = (t: TFunction<'components', undefined, 'components'>, pathnames: string[]) => {
  // if (pathnames.includes('private')) {
  //   return [{ label: t('breadcrumbsItems.toPl'), path: `/${pathnames[0]}/${pathnames[1]}/${pathnames[2]}` }];
  // }

  // if (pathnames.includes('playlistview')) {
  //   return [
  //     { label: t('breadcrumbsItems.toHome'), path: '/' },
  //     { label: t('breadcrumbsItems.toPl'), path: `/${pathnames[0]}/${pathnames[1]}` },
  //   ];
  // }

  // if (pathnames.includes('playlist')) {
  //   return [
  //     { label: t('breadcrumbsItems.toLib'), path: '/' },
  //     { label: t('breadcrumbsItems.toPl'), path: `/${pathnames[0]}/${pathnames[1]}` },
  //   ];
  // }

  // if (pathnames.includes('quiz')) {
  //   return [
  //     { label: t('breadcrumbsItems.toLib'), path: '/' },
  //     { label: t('breadcrumbsItems.toPl'), path: `/playlist/${pathnames[1]}` },
  //     { label: t('breadcrumbsItems.toVideo'), path: `quiz/${pathnames[1]}` },
  //   ];
  // }

  return [
    { label: t('breadcrumbsItems.toLib'), path: '/' },
    // { label: t('breadcrumbsItems.toPl'), path: pathnames[0] },
    { label: t('breadcrumbsItems.toVideo'), path: pathnames[1] },
  ];
};
