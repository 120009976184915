import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HeroStyled = styled.section`
  min-height: 70vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InnerWrapper = styled(motion.div)`
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 60px;
  line-height: 60px;
  padding: 0 16px;
  max-width: 1000px;
  margin-bottom: 64px;
  margin-left: auto;
  margin-right: auto;
  color: transparent;
  font-weight: 700;
  background-clip: text;
  user-select: 'none';
  background-image: linear-gradient(to right, #f3e8ff, #d8b4fe, #ec4899);

  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 48px;
  }
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 32px;
  }
  //@media screen and (max-width: 640px) {
  //  font-size: 32px;
  //  line-height: 38px;
  //}
`;

export const Gradient = styled.div`
  position: absolute;
  inset: 0px;
  background-image: linear-gradient(to bottom, #0f0a2b, #1a1145, transparent);
`;
export const GradientGrid = styled.div`
  position: absolute;
  inset: 0px;
  background-size: 58px 58px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  mask-image: radial-gradient(ellipse at center, black 20%, transparent 75%);
`;
