import { TarifItem } from './TarifItem';
import {
  BonusValue,
  ButtonPay,
  Count,
  CountText,
  CountTitle,
  PaymentWrapper,
  PayWrapper,
  PriceLine,
  Promocode,
  PromocodeButton,
  PromocodeInput,
  PromocodeWrapper,
  Shadow,
  SumBlock,
} from './TarifItem/TarifItem.styled';

import { ContentWrapper } from '../BalancePage.styled';
import PriceRange from '../PriceRange/PriceRange';

import { useEffect, useState } from 'react';

import { Check } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import {
  useCheckPromoCodeMutation,
  useGeneratePaymentUrlMutation,
  useGetBonusesQuery,
  useGetServicesQuery,
} from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLoader/FullScreenLoader';
import { useActions } from '@/hooks';

export const TariffsTab = () => {
  // const { t } = useTranslation('pages');
  const [promoCode, setPromoCode] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(1000);
  const [bonusAmount, setBonusAmount] = useState(0);
  const [promoBonus, setPromoBonus] = useState(0);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useActions();

  const { data: services } = useGetServicesQuery();
  const [checkPromoCode] = useCheckPromoCodeMutation();
  const [generatePaymentUrl] = useGeneratePaymentUrlMutation();
  const { data: bonuses, isSuccess } = useGetBonusesQuery();
  const location = useLocation();

  useEffect(() => {
    if (bonuses) {
      let bonus = 0;

      // Начинаем с 2-го элемента
      for (let i = 1; i < bonuses.length; i++) {
        const level = bonuses[i];

        if (paymentAmount >= level.data.minCount && paymentAmount <= level.data.maxCount) {
          bonus = (paymentAmount * level.data.bonusPercent) / 100;
          break;
        }
      }

      setBonusAmount(bonus);
    }
  }, [paymentAmount, promoBonus, isSuccess, bonuses]);

  const handlePayment = async () => {
    setIsLoading(true);
    try {
      const depositBonusId = bonuses
        ? bonuses.find((item) => paymentAmount >= item.data.minCount && paymentAmount <= item.data.maxCount)
            ?.publicId || ''
        : '';

      const currencyCode = bonuses && bonuses.find((item) => item)?.data.currencyCode;

      const paymentURL = await generatePaymentUrl({
        amount: paymentAmount,
        currency_code: currencyCode ?? '',
        bonus_amount: bonusAmount + promoBonus,
        promocode: promoCode,
        deposit_bonus_id: depositBonusId,
        ...(location.state?.selectedPlaylist && { playlist: location.state.selectedPlaylist }),
        ...(location.state?.duration && { duration: location.state.duration }),
        ...(location.state?.videoName && { title: location.state.videoName }),
        ...(location.state?.link && { link: location.state.link }),
      }).unwrap();

      window.location.href = paymentURL.paymentUrl;
    } catch (error: any) {
      showNotification({
        text: error?.message || 'Произошла ошибка при обработке платежа.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyPromo = async () => {
    setIsLoading(true);
    try {
      const promoBonus = await checkPromoCode({ promocode: promoCode ?? '' }).unwrap();
      if (promoBonus) {
        setPromoBonus(promoBonus.data.value);
        setIsPromoApplied(true);
      } else {
        setPromoBonus(0);
        setIsPromoApplied(false);
      }
    } catch (error: any) {
      showNotification({
        text: error?.message || 'Промокод не найден.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {services && bonuses && (
        <>
          <ContentWrapper>
            {services.map((service) => (
              <TarifItem
                key={service.publicId}
                tarif={service}
                paymentAmount={paymentAmount + bonusAmount + promoBonus}
              />
            ))}
          </ContentWrapper>
          <PriceLine>
            <Shadow>
              <PriceRange bonuses={bonuses} paymentAmount={paymentAmount} setPaymentAmount={setPaymentAmount} />

              <SumBlock>
                <PaymentWrapper>
                  <CountTitle>Сумма пополнения</CountTitle>
                  <Count>
                    <CountText>{paymentAmount} ₽</CountText>
                    {(bonusAmount > 0 || promoBonus > 0) && (
                      <BonusValue>+{bonusAmount + promoBonus} ₽ бонус</BonusValue>
                    )}
                  </Count>
                </PaymentWrapper>
                <PaymentWrapper>
                  <CountTitle>Промокод</CountTitle>
                  <PromocodeWrapper>
                    <Promocode>
                      <PromocodeInput
                        disabled={isPromoApplied}
                        value={promoCode}
                        onChange={(e) => {
                          setPromoCode(e.target.value);
                        }}
                        placeholder="Введите промокод"
                      />
                      <PromocodeButton onClick={handleApplyPromo} disabled={!promoCode || isLoading || isPromoApplied}>
                        {isPromoApplied ? <Check className="w-5 h-5" /> : 'Применить'}
                      </PromocodeButton>
                    </Promocode>
                  </PromocodeWrapper>
                </PaymentWrapper>
              </SumBlock>

              <PayWrapper initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
                <ButtonPay onClick={() => handlePayment()} disabled={isLoading}>
                  Оплатить {paymentAmount} ₽
                </ButtonPay>
              </PayWrapper>
            </Shadow>
          </PriceLine>
        </>
      )}
    </>
  );
};
