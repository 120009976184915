import { lazy } from 'react';

export * from './Activate';
export { default as Main } from './MainPage/MainPage';
export { default as User } from './UserPage/UserPage';
export { default as Profile } from './ProfilePage/ProfilePage';
export { PlaylistsWrapper, VideosWrapper, FilesWrapper } from './LibraryPage';
export { default as PlayListOutlet } from './PlayListOutlet/PlayListOutlet';
export { default as Playlist } from '@/pages/IframePlayListPage/IframePlayListPage';
export { Library, LibraryLazy } from './LibraryPage';
export { default as Onboarding } from './OnboardingPage/OnboardingPage';
export { default as NotFoundPage } from './NotFoundPage/NotFoundPage';
export const Password = lazy(() => import('./Password/Password'));
export * from './PolicyPage';
export * from './SuccessPaymentPage';
export * from './Search/SearchPage';
export { default as ErrorPage } from './ErrorPage/ErrorPage';

export const OnboardingLazy = lazy(() => import('./OnboardingPage/OnboardingPage'));
export * from './AdminPage';
export * from './VideoPage';
export * from './BalancePage';
export * from './Lending';
