import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  background: rgb(37, 26, 86);
    
  > button {
      flex: 1;
  }
    
  @media (max-width: 768px) {
      padding: 8px 0;
      position: sticky;
      bottom: 0;
      z-index: 999;
  }
`;

export const TimecodesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10px;
`;

export const Wrapper = styled.div`
  padding: 16px;
  background-color: #251a56;
  max-width: 598px;
  width: 100%;
  max-height: 708px;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  gap: 30px;
  margin: 0 auto;
  justify-content: center;
    
    @media (max-width: 768px) {
        padding: 8px;
        padding-bottom: 0;
    }
`;

// export const VideoTimecodesWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   display: grid;
//   grid-template-columns: 3fr 2fr;
//   gap: 30px;
//   justify-content: center;
//   align-items: flex-start;
// `;

// export const WrapperForSlim = styled.div`
//   position: fixed;
//   right: 5%;
//   width: 33%;
//
//   @media screen and (max-width: 768px) {
//     position: relative;
//     width: 100%;
//     right: 0%;
//   }
// `;

export const TimecodesButtonWrapper = styled.div`
  overflow-y: scroll;
  max-height: 555px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #1a1145;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #62548b;
  }
`;
