type DeleteIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

const TrashIcon = ({ height, width, fill }: DeleteIconProps) => (
  <svg
    width={width ? width : '69'}
    height={height ? height : '68'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 15L10 12" stroke={fill ? fill : '#8d8dec'} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14 15L14 12" stroke={fill ? fill : '#8d8dec'} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M6 7V16C6 17.8856 6 18.8284 6.58579 19.4142C7.17157 20 8.11438 20 10 20H14C15.8856 20 16.8284 20 17.4142 19.4142C18 18.8284 18 17.8856 18 16V7"
      stroke={fill ? fill : '#8d8dec'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 7H21" stroke={fill ? fill : '#8d8dec'} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M10.0681 3.37059C10.1821 3.26427 10.4332 3.17033 10.7825 3.10332C11.1318 3.03632 11.5597 3 12 3C12.4403 3 12.8682 3.03632 13.2175 3.10332C13.5668 3.17033 13.8179 3.26427 13.9319 3.37059"
      stroke={fill ? fill : '#8d8dec'}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default TrashIcon;
