import { Complete } from './Complete';
import { QuestionPage } from './QuestionPage/QuestionPage';
import { QuestionInfo } from './QuestionPage/QuestionPage.styled';

import { Text } from '../TimecodesTab/TimecodesTab.styled';
import { TitleTranscript, TitleTranscriptWrapper } from '../TranscriptVideo/TranscriptVideo.styled';
import { ContentWrap, VideoCardV2Wrapper } from '../VideoPage.styled';

import { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetVideoAllQuizzesQuery } from '@/api';
import { EditMaterialsButton } from '@/components';
import { useAppSelector, useHandlingError } from '@/hooks';
import { IframeThemeType, StatusType } from '@/types';

interface QuizProps {
  publicId?: string;
  playlistId?: string;
  goToTime: (time: number) => void;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType?: string;
  quizStatus?: StatusType | undefined;
  isIframe?: boolean;
  iframeTheme?: string;
}

export const QuizTab = ({
  publicId = '',
  playlistId = '',
  goToTime,
  hash,
  videoPublicId = '',
  quizStatus,
  iframeTheme,
  isIframe,
}: QuizProps) => {
  const { t } = useTranslation('components');

  const { data, isLoading, error } = useGetVideoAllQuizzesQuery(
    { playlistId, videoPublicId, hash },
    { skip: Boolean(publicId) },
  );
  const { catchError } = useHandlingError();
  const navigate = useNavigate();
  const location = useLocation();
  const isLandingPage = location.pathname.includes('landing');

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  useEffect(() => {
    catchError(error);
  }, [error]);

  if (
    quizStatus === 'NOT_STARTED' ||
    quizStatus === 'IN_PROGRESS' ||
    quizStatus === 'FAILED' ||
    (quizStatus === 'DONE' && !data) ||
    (data && data.data.length === 0)
  ) {
    return (
      <Text isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        Видео обрабатывается
      </Text>
    );
  }

  return (
    <VideoCardV2Wrapper isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
      <TitleTranscriptWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleTranscript isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {t('title_quiz')}
          </TitleTranscript>
          <QuestionInfo isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>{`${t(
            'quizPage.question_quiz',
            {
              ns: 'pages',
              activeQuestionIndex: activeQuestionIndex + 1,
              questionsLength: questions.length,
            },
          )}`}</QuestionInfo>
        </div>
        {!isIframe && !isLandingPage && (
          <EditMaterialsButton onClick={() => navigate(`/account/edit/${videoPublicId}/quiz`)} />
        )}
      </TitleTranscriptWrapper>

      <ContentWrap isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
        {isLoading && (
          <div style={{ margin: '0 auto' }}>
            <CircularProgress color="secondary" size={40} />
          </div>
        )}
        {quizStatus === 'DONE' && data && data.data.length > 0 && !done && (
          <QuestionPage
            isIframe={isIframe}
            iframeTheme={iframeTheme as IframeThemeType}
            {...questions[activeQuestionIndex]}
            goToTime={goToTime}
          />
        )}
        {done && <Complete isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType} />}
      </ContentWrap>
    </VideoCardV2Wrapper>
  );
};
