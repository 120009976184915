import { ButtonsWrapper, Inner } from './RemoveQuiz.styled';

import { Modal, Title } from '../Modal';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDeleteQuizzesMutation } from '@/api';
import { Button, FullScreenLoader } from '@/components';
import { useActions } from '@/hooks';

interface RemovePlaylistProps {
  publicId: string;
  videoId: string;
  playlistId: string;
}

export const RemoveQuiz = ({ videoId, publicId }: RemovePlaylistProps) => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();

  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deleteQuiz, { isLoading }] = useDeleteQuizzesMutation();

  const deletePL = async () => {
    try {
      setIsDelete(true);
      await deleteQuiz({ videoId, publicId }).unwrap();

      closeModal();

      showNotification({
        text: t('del_quiz', { ns: 'success' }),
        severity: 'success',
      });
    } catch (e) {
      closeModal();
      setIsDelete(false);
      showNotification({
        text: t('del_quiz_err', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  return (
    <Modal modal={'RemoveQuiz'} closeButton>
      <Inner>
        <Title>{t('delete_quiz')}</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deletePL} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={() => closeModal()} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
