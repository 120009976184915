import { TitleH4 } from '../../styles/components';

import styled, { css } from 'styled-components';

import MyInput from '@/components/Shared/MyInput/MyInput';
import { theme } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const Wrapper = styled.div`
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
  width: 100%;

  margin: 40px 0;

  & .toolbarClassName > div {
    display: flex;
  }

  @media screen and (max-width: 1024px) {
    margin: 20px 0;
  }
  @media screen and (max-width: 480px) {
    margin: 10px 0;
  }
`;

export const Title = styled(TitleH4)`
  margin: 0 0 30px 0;
`;

export const SliderSection = styled.div`
  margin-bottom: 30px;

  .slick-arrow {
    top: -60px;
  }

  .slick-arrow.slick-next {
    right: 30px;
  }

  .slick-arrow.slick-prev {
    right: 60px;
    left: auto;
  }
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 500px;
`;

export const TitleWithButtonWrapper = styled.div`
  display: flex;
  position: relative;
  //justify-content: space-between;
  //align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;

  & > button {
    flex-shrink: 0;
  }
`;

export const InfoBlock = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #16066c;
  border-radius: 10px;
  max-width: 446px;
  padding: 20px;
  top: 50px;
  left: 30px;
`;
export const InfoText = styled.p<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  color: #e4e4ff;
  font-size: 17px;
  line-height: 130%;
  font-weight: normal;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const IconInfo = styled.img`
  cursor: pointer;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;

export const AdminVideoOutletStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  
  @media (max-width: 725px) {
    gap: 16px;
  }
`;

export const SearchInput = styled(MyInput)`
  input {
    border-radius: 10px;
    display: flex;
    width: 100%;
    padding: 12px 16px 14px 60px;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 480px) {
      padding-left: 40px;
    }
  }

  svg {
    position: absolute;
    top: 25%;
    left: 2%;
    path {
      stroke: #e4e4ff;
    }
  }
`;
