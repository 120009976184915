import { ContactIcon } from './assets/ContactIcon';
import {
  Copy,
  FooterStyled,
  GeneralInfoWrapper,
  Info,
  InfoIconsWrapper,
  InfoWrapper,
  List,
  Text,
  TitleNewH3,
} from './Footer.styled';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Container } from '@/components'; // import { useActions } from '@/hooks';
// import { useActions } from '@/hooks';

// import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const location = useLocation();
  const libraryPage = location.pathname === '/';
  const getYear = new Date().getFullYear();
  const { i18n } = useTranslation();

  const handleImageError = (event: any) => {
    event.target.style.display = 'none';
  };

  // const { setFinishedOnboarding } = useActions();

  const docunents =
    i18n.language === 'ru'
      ? [
          { label: 'Пользовательское соглашение', to: '/policy/personal' },
          { label: 'Политика конфиденциальности', to: '/policy/privacy' },
        ]
      : [
          { label: 'Public Privacy Policy', to: '/policy/privacy' },
          { label: 'Terms of use', to: '/policy/terms_of_use' },
        ];

  return (
    <FooterStyled>
      <Container libraryPage={libraryPage}>
        <GeneralInfoWrapper>
          <div>
            <TitleNewH3>ViSaver</TitleNewH3>
            <List>
              <li>
                <Text>Интеллектуальный помощник для работы с видеоконтентом</Text>
              </li>
              {/* <li>
                <IconsList>
                  <li>
                    <Link to="#">
                      <svg
                        style={{ width: '24px', height: '24px' }}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <svg
                        style={{ width: '24px', height: '24px' }}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <svg
                        style={{ width: '24px', height: '24px' }}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </li>
                </IconsList>
              </li> */}
            </List>
          </div>

          {/* Quick Links */}
          <div>
            {/*
            <TitleNewH3>Быстрые ссылки</TitleNewH3>
            <List>
              {/* <li>
                <button onClick={() => setFinishedOnboarding()}>{'О нас'}</button>
              </li>
              {['О нас', 'Возможности', 'Тарифы', 'Блог'].map((item) => (
                  <li key={item}>
                    <Link to="#">{item}</Link>
                  </li>
              ))}
            </List>*/}
          </div>

          {/* Legal */}
          <div>
            <TitleNewH3>Документы</TitleNewH3>
            <List>
              {docunents.map(({ label, to }, index) => (
                <li key={index}>
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </List>
          </div>

          {/* Contact */}
          <div>
            <TitleNewH3>Контакты</TitleNewH3>
            <List>
              <li>
                <ContactIcon />
                <Link to="mailto:support@visaver.com">support@visaver.com</Link>
              </li>
              <Link
                style={{ display: 'flex', alignContent: 'center', gap: '8px' }}
                to="https://t.me/ViSaver_Support"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69a.2.2 0 00-.05-.18c-.06-.05-.14-.03-.21-.02-.09.02-1.49.95-4.22 2.79-.4.27-.76.41-1.08.4-.36-.01-1.04-.2-1.55-.37-.63-.2-1.12-.31-1.08-.66.02-.18.27-.36.74-.55 2.92-1.27 4.86-2.11 5.83-2.51 2.78-1.16 3.35-1.36 3.73-1.36.08 0 .27.02.39.12.1.08.13.19.14.27-.01.06.01.24 0 .38z" />
                </svg>
                <span>@ViSaver_Support</span>
              </Link>
              {/* <li>
                <svg style={{ width: '24px', height: '24px' }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <Link to="tel: 8 800 555-35-35">8 800 555-35-35</Link>
              </li> */}
            </List>
          </div>
        </GeneralInfoWrapper>
        <InfoWrapper>
          <Info>
            <Copy>© {getYear} ViSaver. Все права защищены.</Copy>
            <InfoIconsWrapper>
              <img
                src={'./assets/sk.svg'}
                alt="Сколково"
                width={85}
                height={30}
                loading="lazy"
                onError={handleImageError}
              />
              <img
                src={'./assets/sber.svg'}
                alt="Сбер"
                width={250}
                height={30}
                loading="lazy"
                onError={handleImageError}
              />
            </InfoIconsWrapper>
          </Info>
        </InfoWrapper>
      </Container>
    </FooterStyled>
  );
};
