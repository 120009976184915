import { api } from '@/api';
import { type AdminSummary, type GetList } from '@/types';

const PATH = 'videos';

export const summariesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSummaries: build.query<GetList<AdminSummary>, { videoId: string; hash?: string }>({
      query: ({ videoId = '', hash }) => ({
        url: `${PATH}/${videoId}/summaries/`,
        method: 'GET',
        params: { hash },
      }),
      providesTags: (_, __, { videoId }) => [{ type: 'summaries', id: videoId }],
    }),

    patchSummary: build.mutation<AdminSummary, { videoId: string; publicId: string; body: Partial<AdminSummary> }>({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/summaries/${publicId}/`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (_, __, { videoId }) => [{ type: 'summaries', id: videoId }],
    }),

    deleteSummary: build.mutation<void, { videoId: string; publicId: string }>({
      query: ({ publicId, videoId }) => ({
        url: `${PATH}/${videoId}/summaries/${publicId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'summaries', id: videoId }],
    }),
  }),
});

export const { usePatchSummaryMutation, useGetAllSummariesQuery, useDeleteSummaryMutation } = summariesAPI;
