import MyInput from '../../components/Shared/MyInput/MyInput';
import { theme } from '../../styles/theme';

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
  @media screen and (max-width: 1024px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 480px) {
    padding: 30px 0;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 1024px) {
    gap: 15px;
  }
  @media screen and (max-width: 360px) {
    gap: 6px;
  }
`;

export const Content = styled.div`
  max-width: 900px;
`;

export const UserImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 20px;
  background-color: ${theme.colors.blue.blue_5};
  object-fit: cover;
  object-position: center;
  display: block;
`;

export const HeaderTitle = styled.h1`
    font-size: 24px;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 16px;
    color: ${theme.colors.text.white_100};

    @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 34px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const Form = styled.form`
  max-width: 495px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media screen and (max-width: 1024px) {
    gap: 26px;
  }
  @media screen and (max-width: 480px) {
    gap: 21px;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 360px) {
    gap: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 14px;
  margin-top: 17px;
  @media screen and (max-width: 1024px) {
    margin-top: 2px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 8px;
    gap: 17px;
    & button {
      width: calc(50% - 17px / 2);
      padding: 12px 0;
    }
  }
`;

export const PhotoInputWrapper = styled.div<{ content?: string | null }>`
  position: relative;
  width: max-content;
  padding-top: 7px;

  & label {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    width: 180px;
    height: 180px;
    /* background-color: ${theme.colors.blue.blue_5}; */
    background-color: transparent;
    /* opacity: 0; */

    font-weight: 700;
    font-size: 15px;
    line-height: 140%;

    text-align: center;

    color: ${theme.colors.white.white_100};

    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.white.white_10};
      /* z-index: 2; */

      &::after {
        content: '${(props) => props.content}';
      }
    }

    @media screen and (max-width: 480px) {
      width: 150px;
      height: 150px;
    }

    & input {
      display: none;
    }

    & div {
      transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);

      &:hover {
        opacity: 0.2;
      }
    }
  }

  & span {
    &:last-child {
      bottom: -35px;
      //width: 360px;
    }
  }
`;
export const MyPhotoInput = styled(MyInput)`
  & span {
    bottom: -35px;
    width: 360px;
  }
`;
export const ButtonsAvatarWrapper = styled.div`
  position: absolute;

  bottom: -5%;
  right: -10%;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${theme.colors.blue.blue_5};
  opacity: 0.8;
  z-index: 2;
  border-radius: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
