import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme } from '@/styles';

import { IframeThemeType } from '@/types';

type VideoWrapperPropsType = {
  iframeTheme?: IframeThemeType;
};

export const CardList = styled.div`
  position: relative;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const VideoWrapper = styled.div<VideoWrapperPropsType>`
  width: 100%;
  /* height: 500px; */
  background-color: ${theme.colors.blue.blue_5};
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('https://catherineasquithgallery.com/uploads/posts/2021-02/1612753659_156-p-fon-gradient-goluboi-fioletovii-180.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 75px 50px rgba(11, 0, 44, 0.49);

  ${({ iframeTheme }) =>
    iframeTheme === IframeThemeType.light &&
    css`
      box-shadow: none;
    `}
`;

export const Container = styled.div<{ isIframe?: boolean }>`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const VideoCardV2Wrapper = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  max-width: 100%;

  background-color: #1a1145;
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  @media (max-width: 480px) {
    padding: 8px !important;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: rgba(60, 64, 67, 0.07);
    `}
`;

export const Inner = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: #251a56;
  border-radius: 1rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 16px;
  position: relative;

  @media (max-width: 480px) {
    padding: 8px;
  }

  @media (min-width: 480px) {
    padding: 16px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: white !important;
    `}
`;

export const Content = styled.div`
  gap: 10px;
  display: flex;
  position: relative;

  @media (min-width: 648px) {
    gap: 28px;
  }

  @media (min-width: 1280px) {
    gap: 48px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const Video = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: linear-gradient(to bottom right, rgba(168, 85, 247, 0.05), transparent, transparent);
  width: 100%;
  max-width: 50%;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

export const StyledLink = styled(Link)`
  color: ${theme.colors.white.white_100};
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-left: 35px;
  margin-bottom: 30px;

  &:hover {
    opacity: 0.7;
  }
`;

export const VideoPlayer = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(168, 85, 247, 0.3);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background-color: #1a1145;
  margin-bottom: 24px;

  @media (max-width: 480px) {
    margin-bottom: 8px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: white;
    `}
`;

export const ContentBlock = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 8px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #111; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: rgb(59 42 126); /* цвет плашки */
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      ::-webkit-scrollbar-track {
        background-color: rgba(60, 64, 67, 0.07); /* цвет дорожки */
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 20px; /* закругления плашки */
        background-color: rgb(190, 190, 241); /* цвет плашки */
      }
    `}
`;

export const MaterialWrapper = styled.div`
  flex: 1;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: unset;
    width: 100%;
    gap: 8px;
  }
`;

export const ContentWrap = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: rgb(37 26 86);
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 16px;
  flex: 1;
  position: relative;
  display: flex;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgba(60, 64, 67, 0.07);
    `}
`;

export const Title = styled.p<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
    //margin-left: 20px;
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;
