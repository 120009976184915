import { PlaylistCardStyleProps } from './PlaylistCard.props';

import { DescriptionText, TitleH5 } from '../../../styles/components';
import { theme } from '../../../styles/theme';

import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

export const PlaylistCardStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div<PlaylistCardStyleProps>`
  background-color: ${theme.colors.blue.blue_20};
  background-image: url(${({ bgImage }) => `${bgImage}`});
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const Inner = styled.div`
  padding: 16px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 60%;
  width: max-content;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  overflow: hidden;
`;

export const UserName = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: ${theme.colors.white.white_100};
  padding: 9px 12px 9px 8px;
  user-select: none;
  white-space: nowrap; /*Запрещаем перенос строк*/
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
`;

export const VideoQuantity = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #e4e4ff;
  padding: 12px 9px;
  display: flex;
  height: 36px;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  width: max-content;
  align-items: center;
  user-select: none;
  text-transform: lowercase;
`;

export const StatisticsList = styled.ul`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
`;

export const StatisticsItem = styled.li`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  user-select: none;
  color: ${theme.colors.text.white_80};
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled(TitleH5)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  color: ${theme.colors.text.white_80};

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;
export const SubTitle = styled(DescriptionText)`
  color: ${theme.colors.text.white_80};
`;
