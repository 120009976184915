import styled, { css } from 'styled-components';

export const MainContainer = styled.main<{ $isCollapsed: boolean }>`
  flex: 1;
  //padding: 1.5rem;
  width: 100%;
  transition: all 300ms ease-in-out;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${({ $isCollapsed }) =>
    $isCollapsed
      ? css`
          @media screen and (max-width: 768px) {
            z-index: 60;
            position: relative;
          }
        `
      : css`
          @media screen and (max-width: 768px) {
            z-index: 60;
            position: relative;
          }
        `}
`;

export const SearchWrapper = styled.div`
    
`;

export const LibraryRightSide = styled.div`
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media screen and (max-width: 768px) {
        padding: 8px;
        padding-right: 0;
        padding-left: 66px;        
    }
`

export const PlaylistSidebarBottomButtons = styled.div`
    display: flex;
    padding: 8px;
    background: rgb(15, 10, 43);
    position: sticky;
    bottom: 0;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;
