import styled, { css } from 'styled-components';

import { ButtonQuestionTab } from '@/pages/VideoPage/QuizTab/QuestionPage/QuestionPage.styled';
import { theme, TitleH4 } from '@/styles';
import { IframeThemeType } from '@/types/iframeTypes';

export const CompleteStyled = styled.div`
  display: flex;
  min-height: 300px;
  margin: 0 auto;

  align-items: center;
  justify-content: center;
`;

export const CompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const TitleComplete = styled(TitleH4)<{ iframeTheme: IframeThemeType; isIframe?: boolean }>`
  font-weight: 700;
  text-transform: uppercase;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: ${theme.colors.blue.blue_dark};
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  
  > button {
    flex: 1;
    font-size: 14px;
  }

  @media (max-width: 568px) {
    button {
      width: 100%;
    }
  }
`;

export const ButtonComplete = styled(ButtonQuestionTab)``;
