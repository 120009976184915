import { Outlet } from 'react-router-dom';

import { useIframe } from '@/hooks/useIframe';
import { BreadCrumbs } from '@/pages/PlayListOutlet/components';

const PlayListOutlet = () => {
  const { isIframe } = useIframe();
  return (
    <>
      {!isIframe && <BreadCrumbs />}
      {/* <VideosArrows /> */}
      <Outlet />
    </>
  );
};

export default PlayListOutlet;
