import styled, { css } from 'styled-components';

import { theme } from '@/styles';

export const Container = styled.div`
  position: relative;
  //background-color: #0d0823;
  //padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  //padding-right: 7px;

  ::-webkit-scrollbar {
    width: 7px; /* ширина scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.modal.purple_300}; /* цвет плашки */
  }
`;

export const TariffsWrapper = styled.div<{ isDisabled?: boolean }>`
  background-color: ${theme.colors.modal.purple_800};
  padding: 1rem;
  border-radius: 12px;
  transition: all 0.2s;
  display: grid;
  height: 100%;

  &:hover {
    background-color: ${theme.colors.modal.purple_700};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

export const TariffsContainerBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(13, 8, 35, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

export const Tarif = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 12px;
`;

export const UnlimitedText = styled.div`
  border-radius: 25px;
  background: linear-gradient(to right, #c084fc, #f472b6);
  color: white;
  margin-left: 10px;
  padding: 2px 8px;
  font-weight: 100;
`;

export const TariffsText = styled.h4`
  font-size: 17px;
  font-weight: 500;
  color: white;
  margin-bottom: 10px;
`;

export const Price = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  color: ${theme.colors.modal.text.purple};
  gap: 0.25rem;
  line-height: 1.25rem;
`;

export const TarifDetailList = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TarifDetail = styled.li`
  color: ${theme.colors.modal.text.purple};
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.25rem;
  gap: 8px;
`;

export const DotItem = styled.div`
  background-color: ${theme.colors.modal.text.purple};
  width: 6px;
  height: 6px;
  border-radius: 9999px;
`;
