import { Search } from 'lucide-react';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  position: relative;
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(216, 180, 254, 0.5);
`;

export const VideoGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const NotVideoText = styled.span`
  display: flex;
  justify-content: center;
  color: rgba(216, 180, 254, 0.5);
  font-size: 16px;
  margin-top: 15%;
`;
