import { memo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { PlayButton } from '@/components';
import { VideoDropDownMenu } from '@/components/Shared/VideoDropdown/VideoDropDownMenu/VideoDropDownMenu';
import { VideoDropDownWrapper } from '@/components/Shared/VideoDropdown/VideoDropDownWrapper/VideoDropDownWrapper';
import DotsVerticalIcon from '@/components/SvgIcons/DotsVerticalIcon';
import PlayIcon from '@/components/SvgIcons/PlayIcon';
import { useActions } from '@/hooks';
import {
  CardContent,
  CardHeader,
  TitleCard,
  VideoCardItemWrapper,
  VideoPreview,
} from '@/pages/LibraryPageV2/VideoCardV2/VideoCardV2.styled';
import { Playlist, Video, VideoInPlaylist } from '@/types';

const VideoCard = ({
  movie,
  to,
  playlistsList,
  isIframe,
  selectedPlaylistId,
}: {
  movie: VideoInPlaylist | Video;
  to?: string;
  playlistsList: Playlist[];
  isIframe?: boolean;
  selectedPlaylistId: string | null;
}) => {
  const { t } = useTranslation('components');
  const { title, thumbnailUrl } = movie;
  const location = useLocation();
  const [addToPlayList] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [deleteFromPlayList] = playlistsAPI.useRemoveVideoFromPlaylistMutation();
  const { showNotification } = useActions();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const [playlistsForVideo, setPlaylistsForVideo] = useState(movie.playlists ?? []);

  const handleTogglePlaylist = async (videoId: string, playlistId: string, isSelected: boolean) => {
    console.log(isSelected);
    try {
      if (isSelected) {
        await deleteFromPlayList({ videoPublicId: videoId, playlistId });
        setPlaylistsForVideo((prev) => prev.filter((id) => id !== playlistId));
        showNotification({
          text: t('del_v', { ns: 'success' }),
          severity: 'success',
        });
      } else {
        await addToPlayList({
          playlistId: playlistId,
          videos: [{ videoPublicId: videoId, isAiSuggested: false }],
        });
        setPlaylistsForVideo((prev) => [...prev, playlistId]);
        showNotification({
          text: t('add_videos_pl_n', {
            ns: 'success',
            name: playlistsList.find((p) => p.publicId === playlistId)?.title ?? '',
          }),
          severity: 'success',
        });
      }
    } catch (e) {
      showNotification({
        text: 'Произошла ошибка, повторите позже',
        severity: 'error',
      });
    }
  };

  return (
    <VideoCardItemWrapper>
      <Link to={to ?? ''} state={`${location.pathname}`}>
        <VideoPreview bgImage={thumbnailUrl}>
          <PlayButton>
            <PlayIcon />
          </PlayButton>
        </VideoPreview>
      </Link>
      <CardContent>
        <CardHeader>
          <TitleCard>{title.slice(0, 100)}</TitleCard>
          {!isIframe && (
            <VideoDropDownWrapper>
              <DotsVerticalIcon onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
              {isDropdownOpen && (
                <VideoDropDownMenu
                  playlistsForVideo={playlistsForVideo}
                  isDropdownOpen={isDropdownOpen}
                  ref={dropdownRef}
                  onClose={() => setIsDropdownOpen(false)}
                  selectedPlaylistId={selectedPlaylistId}
                  video={movie}
                  playlistsList={playlistsList}
                  handleTogglePlaylist={handleTogglePlaylist}
                />
              )}
            </VideoDropDownWrapper>
          )}
        </CardHeader>

        {/*{playlists.length > 0 && (*/}
        {/*  <div className="mt-2 flex flex-wrap gap-2">*/}
        {/*    {playlists.map((playlistId: string) => {*/}
        {/*      const playlist = playlistsList && playlistsList.find(p => p.publicId === playlistId);*/}
        {/*      return playlist ? (*/}
        {/*        <PlaylistTag key={playlistId}>*/}
        {/*          {playlist.title}*/}
        {/*        </PlaylistTag>*/}
        {/*      ) : null;*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*)}*/}
      </CardContent>
    </VideoCardItemWrapper>
  );
};

export default memo(VideoCard);
