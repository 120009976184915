import { api } from '@/api';
import { BaseParams, GetList, QuizApiResponse, TimecodesRequest } from '@/types';

const PATH = 'videos';
export const quizzesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getVideoAllQuizzes: build.query<QuizApiResponse, TimecodesRequest & { hash?: string }>({
      query: ({ videoPublicId = '', hash }) => ({
        url: `${PATH}/${videoPublicId}/quizzes/`,
        method: 'GET',
        params: { linkHash: hash },
      }),
      transformResponse: (response: GetList<QuizApiResponse>) => response.results?.[0],
      providesTags: (_, __, { videoPublicId }) => [{ type: 'quizzes', id: videoPublicId }],
    }),

    getOneQuiz: build.query<QuizApiResponse, { videoId: string; publicId: string; params?: BaseParams }>({
      query: ({ publicId, videoId }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'GET',
      }),
      providesTags: (_, __, { videoId }) => [{ type: 'quizzes', id: videoId }],
    }),

    patchQuizzes: build.mutation<
      QuizApiResponse,
      { videoId: string; publicId: string; body: Pick<QuizApiResponse, 'data'>; params?: BaseParams }
    >({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'quizzes', id: videoId }],
    }),

    deleteQuizzes: build.mutation<void, { videoId: string; publicId: string }>({
      query: ({ videoId, publicId }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'quizzes', id: videoId }],
    }),

    putQuizzes: build.mutation<
      QuizApiResponse,
      { videoId: string; publicId: string; body: Pick<QuizApiResponse, 'data'> }
    >({
      query: ({ videoId, publicId, body }) => ({
        url: `${PATH}/${videoId}/quizzes/${publicId}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { videoId }) => [{ type: 'quizzes', id: videoId }],
    }),
  }),
});

export const { useGetVideoAllQuizzesQuery, usePatchQuizzesMutation, useDeleteQuizzesMutation } = quizzesAPI;
