import { api } from '@/api';
import {
  type BaseAdminRequest,
  type BaseTimecodesRequest,
  type TimecodesResponse,
  type TransformedTimecodesResponse,
} from '@/types';
import { formatDuration } from '@/utils';

const PATH = 'videos';

export const timecodesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getTimecodesAdmin: build.query<TransformedTimecodesResponse, BaseAdminRequest>({
      query: ({ videoPk }) => ({
        url: `${PATH}/${videoPk}/timecodes/`,
        method: 'GET',
      }),
      transformResponse: (response: TimecodesResponse) => {
        const timecodes =
          response.results[0] &&
          response.results[0].data.timecodes
            .filter(
              (obj, index) =>
                index ===
                response.results[0].data.timecodes.findIndex((t) => t.start === obj.start || t.text === obj.text),
            )
            .map((timecode) => ({ ...timecode, start: formatDuration(Math.round(timecode.start as number)) }));

        const publicId = response.results[0]?.publicId; // Извлекаем publicId из результата

        return { timecodes, publicId }; // Возвращаем как объект с обновленной структурой
      },
      providesTags: (_, __, { videoPk }) => [{ type: 'timecodes', id: videoPk }],
    }),

    updatePartialTimecodes: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/${videoPk}/timecodes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { videoPk }) => [{ type: 'timecodes', id: videoPk }],
    }),

    deleteTimecodes: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/${videoPk}/timecodes/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { videoPk }) => [{ type: 'timecodes', id: videoPk }],
    }),
  }),
});

export const {
  useGetTimecodesAdminQuery,
  useLazyGetTimecodesAdminQuery,
  useUpdatePartialTimecodesMutation,
  useDeleteTimecodesMutation,
} = timecodesAPI;
