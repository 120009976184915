import styled, { css } from 'styled-components';

import { InfoBlock } from '@/pages/AdminPage/AdminPage.styled';
import { IframeThemeType } from '@/types/iframeTypes';

export const Tabs = styled.div<{
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
  tabsCount?: number;
  isAuth?: boolean;
}>`
  display: ${({ tabsCount }) => (tabsCount && tabsCount > 2 ? 'grid' : 'none')};
  cursor: pointer;
  position: relative;
  grid-template-columns: ${({ tabsCount }) => `repeat(${tabsCount}, 1fr)`};
  align-items: center;
  justify-content: center;
  background-color: #1a1145;
  padding: 6px;
  border-radius: 0.75rem;
  height: 56px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: rgba(60, 64, 67, 0.07);
    `}
`;
export const TabItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 44px;
  padding: 4px 12px;
  border-radius: 0.5rem;
  transition: all 300ms;
  position: relative;
  color: white;

  :focus-visible {
    outline: none;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;
export const InfoBlockMaterials = styled(InfoBlock)<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: rgb(73, 59, 119);
  top: 60px;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background: #e4e4ff;
    `}
`;
