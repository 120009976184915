import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ContentTitle } from '@/pages/BalancePage/BalancePage.styled';
import { CountTitle } from '@/pages/BalancePage/CalculatorTab/CalculatorTab.styled';
import { TitleDesc } from '@/pages/BalancePage/TariffsTab/TarifItem/TarifItem.styled';
import { theme } from '@/styles';

export const TitleHistory = styled(ContentTitle)`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
`;

export const ContentCardBlockHistory = styled(motion.div)`
  display: flex;
  justify-content: space-between;

  background-color: ${theme.colors.modal.purple_800};
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${theme.colors.modal.purple_300};
  margin-top: 16px;

  :hover {
    border-color: ${theme.colors.modal.purple_500};
  }
`;

export const CountTitleHistory = styled(CountTitle)`
  color: rgb(74 222 128);
`;
export const CountTitleHistoryRed = styled(CountTitle)`
  color: rgb(248 113 113);
`;

export const TitleDescGreen = styled(TitleDesc)`
  color: rgb(74 222 128);
`;
export const TitleDescRed = styled(TitleDesc)`
  color: rgb(248 113 113);
`;
