import { Modal, OtherText, Title } from '../Modal';
import { Info } from '../Registration/Registration.styled';
import { Inner } from '../RemoveUser/RemoveUser.styled';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@/components';
import { useActions, useAppSelector } from '@/hooks';

export const VerifyInfo = () => {
  const { t } = useTranslation('components');
  const [, setParams] = useSearchParams();
  const { closeModal } = useActions();

  const [email] = useAppSelector((state) => [state.modal.data?.email]);

  const closeHandler = () => {
    closeModal();
    setParams();
  };

  return (
    <Modal modal={'verifyinfo'} onClose={closeHandler} closeButton>
      <Title>{t('verify_email', { ns: 'modal' })}</Title>
      <Inner>
        <>
          <OtherText>{t('verify_email_info', { ns: 'modal', email })}</OtherText>
          <Info style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Button
              style={{ padding: '0px', fontSize: '10px', marginTop: '20px' }}
              size="small"
              theme="transparent"
              onClick={() => setParams({ popup: 'resendEmail' })}
            >
              {t('verify_email_resend', { ns: 'modal' })}
            </Button>
          </Info>
        </>
      </Inner>
    </Modal>
  );
};
