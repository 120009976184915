import MyInput from '../MyInput/MyInput';

import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

export const Wrapper = styled.div`
  position: relative;
`;
export const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 56%;
  transform: translateY(-50%);
`;

export const StyledInput = styled(MyInput)<{ iframeTheme: IframeThemeType; isIframe?: boolean }>`
  & input {
    width: 100%;
    padding: 16px;
    padding-left: 48px;
    background-color: #1a1145;
    border: 1px solid rgba(168, 85, 247, 0.2);
    border-radius: 0.75rem;
    color: white;
    transition: all 0.2s;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      color: rgba(216, 180, 254, 0.5);
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-color: rgba(168, 85, 247, 0.5);
    }

    &:hover {
      border-color: rgba(168, 85, 247, 0.5);
    }

    ${({ iframeTheme, isIframe }) =>
      isIframe &&
      iframeTheme === IframeThemeType.light &&
      css`
        background: white;
      `}
  }
`;
