import { ButtonComplete, ButtonsWrapper, CompleteStyled, CompleteWrapper, TitleComplete } from './Complete.styled';

import { useTranslation } from 'react-i18next';

import { useActions, useAppSelector } from '@/hooks';
import { IframeThemeType } from '@/types/iframeTypes';

export const Complete = ({ iframeTheme, isIframe }: { iframeTheme?: IframeThemeType; isIframe?: boolean }) => {
  const { setQuizRestart } = useActions();
  const { t } = useTranslation('pages');

  const [correctCount, questionsCount] = useAppSelector((state) => [
    state.quiz.questions.filter((q) => q.answer === q.correctAnswer).length,
    state.quiz.questions.length,
  ]);

  const setText = () => {
    const correctPercent = Math.round((correctCount / questionsCount) * 100);
    if (correctPercent === questionsCount) {
      return `${t('quizPage.correct_total_quiz')}`;
    } else {
      return `${t('quizPage.count_quiz', { correctCount: correctCount, questionsCount: questionsCount })}`;
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <CompleteStyled>
      <CompleteWrapper>
        {correctCount === questionsCount && (
          <TitleComplete isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {t('quizPage.result_title')}
          </TitleComplete>
        )}
        <TitleComplete isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
          {setText()}
        </TitleComplete>

        <ButtonsWrapper>
          <ButtonComplete
            onClick={() => {
              setQuizRestart();
            }}
          >
            {t('quizPage.restart_quiz_btn')}
          </ButtonComplete>
          <ButtonComplete onClick={handleReload}>{t('quizPage.go_to_video_btn')}</ButtonComplete>
        </ButtonsWrapper>
      </CompleteWrapper>
    </CompleteStyled>
  );
};
