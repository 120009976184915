const DotsVerticalIcon = ({ onClick }: { onClick?: () => void }) => (
  <div onClick={onClick} style={{ cursor: 'pointer', paddingTop: '5px' }}>
    <svg width="24" height="24" viewBox="0 0 4 25" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M2 14.5C0.895431 14.5 3.01408e-07 13.6046 3.49691e-07 12.5C3.97973e-07 11.3954 0.895431 10.5 2 10.5C3.10457 10.5 4 11.3954 4 12.5C4 13.6046 3.10457 14.5 2 14.5Z"
        fill="#E4E4FF"
      />
      <path
        d="M2 24.5C0.89543 24.5 -4.82823e-08 23.6046 0 22.5C4.82823e-08 21.3954 0.89543 20.5 2 20.5C3.10457 20.5 4 21.3954 4 22.5C4 23.6046 3.10457 24.5 2 24.5Z"
        fill="#E4E4FF"
      />
      <path
        d="M2 4.5C0.895431 4.5 6.511e-07 3.60457 6.99382e-07 2.5C7.47665e-07 1.39543 0.895431 0.5 2 0.5C3.10457 0.5 4 1.39543 4 2.5C4 3.60457 3.10457 4.5 2 4.5Z"
        fill="#E4E4FF"
      />
    </svg>
  </div>
);

export default DotsVerticalIcon;
