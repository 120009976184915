import { theme } from '../../../../styles';

import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;
export const Info = styled.a`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  color: ${theme.colors.white.white_100};
  text-decoration: underline;
  cursor: pointer;
    
  > button {
      border: none;
  }

  :hover {
    text-decoration: none;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`;
