export const theme = {
  colors: {
    text: {
      white_100: '#E4E4FF',
      white_90: 'rgb(255 255 255 / 0.9)',
      white_80: '#BBB6DA',
      white_40: '#685FA7',
      white_30: '#62548B',
      white_10: '#3E2C69',
      blue_25: '#17087B',
      purple_200: 'rgb(233 213 255)',
      purple_300: 'rgb(216 180 254)',
      purple_300_50: 'rgb(216 180 254 / 0.5)',
      purple_300_60: 'rgb(216 180 254 / 0.6)',
      purple_400: 'rgb(192 132 252)',
      purple_400_30: 'rgb(192 132 252 / 0.3)',
      purple_500: 'rgb(168 85 247)',
      purple_500_5: 'rgb(168 85 247 / 0.05)',
      purple_500_10: 'rgb(168 85 247 / 0.1)',
      purple_500_20: 'rgb(168 85 247 / 0.2)',
      purple_500_30: 'rgb(168 85 247 / 0.3)',
      purple_500_40: 'rgb(168 85 247 / 0.4)',
      purple_600: 'rgb(147 51 234)',
      purple_600_10: 'rgb(147 51 234 / 0.1)',
      purple_600_20: 'rgb(147 51 234 / 0.2)',
      purple_700: '#7e22ce',
      pink_500_10: 'rgb(248 113 113 / 0.1)',
    },
    white: {
      white_100: '#E4E4FF',
      white_80: '#BBB6DA',
      white_70: '#A8ABEA',
      white_40: '#685FA7',
      white_30: '#62548B',
      white_10: '#2B1759',
    },
    blue: {
      blue_normal: '#1F1EFE',
      blue_70: '#1E15C7',
      blue_55: '#14003C',
      blue_25: '#17087B',
      blue_20: '#16066C',
      blue_5: '#140150',
      blue_dark: '#000046',
      blue_modal: '#0B002C',
    },
    red: {
      red_light: '#EF7A80',
      red_dark: ' #EA525A',
    },
    green: {
      green_light: '#7AEF8D',
      green_dark: '#2B7838',
    },
    pink: {
      pink_500: 'rgb(236 72 153)',
      pink_500_10: 'rgb(236 72 153 / 0.1)',
      pink_500_20: 'rgb(236 72 153 / 0.2)',
      pink_600: '#db2777',
    },
    label: {
      label_light: '#E4E4FF',
      label_dark: '#17087B',
    },
    border: {
      purple_500: 'rgb(168 85 247)',
      purple_500_10: 'rgb(168 85 247/ 0.1)',
      purple_500_20: 'rgb(168 85 247/ 0.2)',
      purple_500_30: 'rgb(168 85 247 / 0.3)',
      purple_500_40: 'rgb(168 85 247 / 0.4)',
    },
    background: {
      purple_card: 'rgb(37 26 86)',
    },
    modal: {
      purple_50: '#1a1145',
      purple_100: '#3b2a7e',
      purple_200: '#251a56',
      purple_300: 'rgba(168, 85, 247, 0.3)',
      purple_400: 'rgb(26, 17, 69)',
      purple_500: 'rgb(168 85 247)',
      purple_500_30: 'rgb(168 85 247 / 0.3)',
      purple_600: '#2a1c61',
      purple_700: 'rgb(42 28 97)',
      purple_800: '#251a56',
      purple_900: 'rgb(192 132 252)',
      purple_hover: '#5E00FF',
      black: '#0d0823',
      purple_disabled: 'rgba(168, 85, 247, 0.1)',
      text: {
        gray: '#a0aec0',
        gray_100: '#d1d5d8',
        white: '#e4e4ff',
        purple: '#D8B4FE',
      },
    },
  },

  fonts: {
    geist: 'Geist, sans-serif',
    geistMono: 'GeistMono, sans-serif',
  },
};
