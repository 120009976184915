import { IconWrapper, StyledInput } from './SearchInVideoInputStyle';

import { useEffect, useRef } from 'react';

import { SearchIcon } from 'lucide-react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '@/hooks';
import { IframeThemeType } from '@/types/iframeTypes';

interface SearchInVideoInputProps {
  getSearch: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  iframeTheme?: IframeThemeType;
  isIframe?: boolean;
  selectedPlaylist?: string | null;
}

const SearchInVideoInput = ({
  getSearch,
  disabled = false,
  placeholder,
  iframeTheme,
  isIframe,
  selectedPlaylist,
}: SearchInVideoInputProps) => {
  const { t } = useTranslation('components');
  const [param, setParam] = useSearchParams();
  const search = param.get('search') || '';

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      getSearch(data);
    }
  }, []);

  //очистка поля поиска при смене плейлиста
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
      getSearch('');
    }
  }, [selectedPlaylist]);

  const makeSearch = useDebounce(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      setParam((prev) => {
        prev.set('search', data);
        return prev;
      });
      getSearch(data);
      ReactGA.event({
        category: 'Search',
        action: 'Search in playlist',
      });
    } else {
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <StyledInput
      isIframe={isIframe}
      iframeTheme={iframeTheme as IframeThemeType}
      placeholder={placeholder ?? t('search_in_video')}
      ref={searchInputRef}
      onChange={onSearch}
      defaultValue={search}
      disabled={disabled}
      icon={
        <IconWrapper>
          <SearchIcon color={'rgba(216, 180, 254, 0.5)'} size={20} />
        </IconWrapper>
      }
    />
  );
};

export default SearchInVideoInput;
