import { Buttons } from './EditSummaryPage.styled';
import { useSummaryFormat } from './hooks';

import { useState } from 'react';

import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { draftToMarkdown } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePatchSummaryMutation } from '@/api';
import { Button, RemoveSummary } from '@/components';
import { useActions } from '@/hooks';
import { AdminSummary } from '@/types';

export const SummaryEditor = ({ summary }: { summary: AdminSummary; isEmpty?: boolean }) => {
  const { videoId } = useParams();
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  const { showNotification } = useActions();
  const [updateSummary, { isLoading }] = usePatchSummaryMutation();

  const { contentState, isHtml } = useSummaryFormat(summary);

  const [value, setValue] = useState(() => EditorState.createWithContent(contentState));

  const handleSaveSummary = async () => {
    try {
      const content = value.getCurrentContent();

      // const rawObject = convertToRaw(content);
      // const markdownString = draftToMarkdown(rawObject);
      // console.log({ markdownString });
      const body: Partial<AdminSummary> = {};
      if (content.hasText()) {
        if (isHtml) {
          const rawObject = convertToRaw(value.getCurrentContent());
          body.html = draftToHtml(rawObject);
        } else {
          body.markdown = draftToMarkdown(convertToRaw(value.getCurrentContent()));
        }

        await updateSummary({
          publicId: summary.publicId,
          body,
          videoId: videoId ?? '',
        }).unwrap();
        showNotification({
          text: t('save_summary', { ns: 'success' }),
          severity: 'success',
        });
        navigate(`/videos/${videoId}`);
        return;
      }
    } catch (e) {
      showNotification({
        text: t('save_summary', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const editorStyle = {
    border: '1px solid #62548B',
    borderRadius: '10px',
    marginTop: '16px',
    height: '500px',
    marginBottom: '0',
  };

  const toolbarStyle = {
    background: '#1A1145',
    border: 'none',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px',
  };

  return (
    <>
      <Editor
        toolbarClassName="toolbarClassName"
        editorState={value}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={setValue}
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
      />
      <Buttons>
        <Button theme="pink" variant="pink" onClick={() => navigate(`/videos/${videoId}`)}>
          Отменить
        </Button>
        <Button
          theme="colored"
          variant="colored"
          onClick={handleSaveSummary}
          disabled={isLoading || !value.getCurrentContent().hasText()}
        >
          {t('admin.save')}
        </Button>
      </Buttons>
      <RemoveSummary publicId={summary.publicId} videoId={videoId || ''} />
    </>
  );
};
