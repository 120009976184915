export const youtubeRegex =
  /^(https?:\/\/)?((?:www|m)\.)?(youtube(?:-nocookie)?\.com|youtu\.be)(\/(?:watch\?v=|embed\/|live\/|v\/|[\w-]+\/)?)([\w-]{11})(\S+)?$/;
export const vkRegex =
  /^(https?:\/\/)?(www\.)?vk\.com\/(video|feed|album|event|video_comments)(\/?(-?\d+_\d+))?(\?.*)?$/;
export const vkVideoRegex = /^(https?:\/\/)?(www\.)?vkvideo\.ru\/(playlist\/[-\d]+_\d+\/)?video-\d+_\d+$/;
// export const kinescopeRegex = /^(https?:\/\/)?(www\.)?kinescope\.io\/(video|embed|watch)\/([A-Za-z0-9\-._~%!$&'()*+,;=]{4,})$/;

export const rutubeRegex = /^(https?:\/\/)?(www\.)?rutube\.ru\/video\/([a-f0-9]{32})\/?$/;

export const combinedRegex =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|vk\.com|kinescope\.io|vkvideo\.ru|rutube\.ru)/;

export const linkRegex =
  /^(https?:\/\/)?(www\.)?(vk\.[a-zA-Z]{2,}|vkvideo\.[a-zA-Z]{2,}|youtu\.[a-zA-Z]{2,}|youtube\.[a-zA-Z]{2,}|rutube\.[a-zA-Z]{2,}|kinescope\.[a-zA-Z]{2,})(\/.*)?$/;
