import { Wrapper } from './EditMaterialsButton.styled';

import { EditIcon } from 'lucide-react';

interface ShareButtonProps {
  onClick: () => void;
}

export const EditMaterialsButton = ({ onClick }: ShareButtonProps) => {
  return (
    <Wrapper onClick={onClick} type="button">
      <EditIcon width={16} height={16} />
      <span>Редактировать</span>
    </Wrapper>
  );
};
