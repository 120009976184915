import { ContentState, convertFromRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { markdownToDraft } from 'markdown-draft-js';

import { AdminSummary } from '@/types';

export const useSummaryFormat = (summary: AdminSummary) => {
  if (summary.markdown) {
    const contentState = convertFromRaw(markdownToDraft(summary.markdown));
    return { contentState, isHtml: false };
  }

  const blocksFromHtml = htmlToDraft(summary.html || '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return { contentState, isHtml: true };
};
