import { TimecodesFormWrapper } from './TimecodesForm.styled';

import { Buttons, TimecodesButtonWrapper, TimecodesWrapper } from '../../EditTimecodesPage.styled';
import { TitleTime } from '../TitleAndTime';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useUpdatePartialTimecodesMutation } from '@/api';
import { Button, FullScreenLoader, RemoveTimecodes } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions, useHandlingError } from '@/hooks';
import { BasePutTimecodesRequest, Timecode, TransformedTimecodesResponse } from '@/types';
import { parseDuration } from '@/utils';

export const TimecodesForm = ({
  timecodes,
  publicId,
  goToTime,
}: TransformedTimecodesResponse & { goToTime: (time: number | string) => void }) => {
  const { videoId } = useParams();
  const { t } = useTranslation('pages');
  const { showNotification } = useActions();
  const [updateTimecodes, { isLoading: isUpdating, error }] = useUpdatePartialTimecodesMutation();
  const navigate = useNavigate();
  const { catchError } = useHandlingError();

  const methods = useForm<{ timecodes: Timecode[] }>({ defaultValues: { timecodes } });

  const onFormSubmit = async ({ timecodes }: { timecodes: Timecode[] }) => {
    try {
      const resultData = {
        data: {
          timecodes: timecodes.map((it) => ({ ...it, start: parseDuration(it.start as string) })),
        },
      };

      await updateTimecodes({
        videoPk: videoId as string,
        publicId: publicId,
        body: resultData as BasePutTimecodesRequest,
      }).unwrap();

      showNotification({
        text: 'Изменения успешно сохранены',
        severity: 'success',
      });
      navigate(`/videos/${videoId}`);
    } catch (e) {
      catchError(error, 'Изменения не сохранены');
    }
  };

  const { fields, insert, remove } = useFieldArray<{ timecodes: Timecode[] }, 'timecodes', 'key'>({
    control: methods.control,
    name: 'timecodes',
    keyName: 'key',
  });

  const handleAddNewTimecodes = (index: number) => {
    insert(index + 1, { start: '', text: '', title: '' });
  };

  return (
    <FormProvider {...methods}>
      {timecodes && (
        <TimecodesFormWrapper onSubmit={methods.handleSubmit(onFormSubmit)}>
          <TimecodesButtonWrapper>
            {isUpdating && <FullScreenLoader />}
            <TimecodesWrapper>
              {fields.map((timecode, index) => (
                <>
                  <TitleTime key={timecode.key} index={index} remove={() => remove(index)} goToTime={goToTime} />
                  <Button
                    style={{
                      padding: '13px',
                      alignSelf: 'center',
                      backgroundColor: '#3B2A7E',
                      border: 'none',
                    }}
                    onClick={() => handleAddNewTimecodes(index)}
                    disabled={isUpdating}
                  >
                    <PlusBigIconBlack />
                  </Button>
                </>
              ))}
            </TimecodesWrapper>
          </TimecodesButtonWrapper>
          <Buttons>
            <Button
              theme="pink"
              variant="pink"
              onClick={() => navigate(`/videos/${videoId}`)}
              disabled={timecodes.length === 0}
            >
              Отменить
            </Button>
            <Button
              theme="colored"
              variant="colored"
              type="submit"
              disabled={isUpdating || timecodes.length === 0 || (methods.formState.errors.timecodes?.length || 0) > 0}
            >
              {t('admin.save')}
            </Button>
          </Buttons>
        </TimecodesFormWrapper>
      )}
      <RemoveTimecodes publicId={publicId} videoPk={videoId || ''} />
    </FormProvider>
  );
};
