import { theme, TitleH4 } from '../../../../styles';

import styled from 'styled-components';

export const Inner = styled.div`
  width: 500px;
    
  @media (max-width: 768px) {
      width: unset;
  }
`;

export const PlayListImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
  background-color: ${theme.colors.blue.blue_25};
`;

export const Title = styled(TitleH4)`
  margin-bottom: 20px;
  padding-right: 20px;
`;

export const Form = styled.form`
  position: relative;
  width: 493px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const SourcesList = styled.ul`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const SourcesItem = styled.li``;

export const SourcesLink = styled.a``;

export const ButtonsWrapper = styled.a`
  display: flex;
  gap: 10px;
`;

export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;
