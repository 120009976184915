import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { playlistsAPI, useGetMyVideosQuery, videosAPI } from '@/api';
import { FullScreenLoader } from '@/components';
import NavBar from '@/components/NavBar/NavBar';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { useHandlingError, useMatchMedia } from '@/hooks';
import { LibraryRightSide } from '@/pages/LibraryPageV2/LibraryPageV2.styled';
import VideoList from '@/pages/LibraryPageV2/VideoList/VideoList';

export const LibraryPageV2 = () => {
  const isTablet = useMatchMedia('(max-width: 767px)');
  const [isCollapsed, setIsCollapsed] = useState(isTablet);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string | null>(null);
  const { catchError } = useHandlingError();
  const [params, setParams] = useSearchParams();
  const searchQueryValue = params.get('search');

  const { data: playlists, isLoading, isSuccess, error: getPlaylistsError } = playlistsAPI.useGetMyPlaylistsQuery({});

  const [
    getSearchVideosInPlaylist,
    { data: searchVideosInPlaylist, isLoading: isSearchInPlaylistLoading, error: searchInPlaylistError },
  ] = playlistsAPI.useLazyGetFullSearchInPlaylistQuery();

  const {
    data: searchVideos,
    isLoading: isSearchVideosLoading,
    error: searchVideosError,
  } = videosAPI.useGetVideosQuery(
    {
      params: {
        title: searchQueryValue || '',
        pageSize: 100,
      },
    },
    { skip: !searchQueryValue || selectedPlaylist !== null },
  );

  const {
    data: playlist,
    isLoading: isPlaylistLoading,
    error: playlistError,
  } = playlistsAPI.useGetPlaylistByIdQuery({ id: selectedPlaylist ?? '' }, { skip: selectedPlaylist === null });

  const { data: videosResponse, isLoading: isGetVideosLoading, error } = useGetMyVideosQuery({ params: { page: 1 } });
  const videos = videosResponse?.results || [];

  const handleSearch = async (query: string) => {
    try {
      const searchQuery = query || params.get('search') || '';
      if (selectedPlaylist) {
        await getSearchVideosInPlaylist({ query: searchQuery, publicId: selectedPlaylist });
      } else {
        setParams(searchQuery ? { search: searchQuery } : {});
      }
    } catch (error) {
      catchError(error);
    }
  };

  const makeSearch = useCallback(
    (query: string) => {
      handleSearch(query);
    },
    [handleSearch],
  );

  const searchResult = useMemo(() => {
    return selectedPlaylist ? searchVideosInPlaylist : searchVideos?.results;
  }, [selectedPlaylist, searchVideosInPlaylist, searchVideos]);

  const filteredVideos = useMemo(() => {
    return selectedPlaylist ? playlist?.videos : videos;
  }, [videos, playlist, selectedPlaylist]);

  // useEffect(() => {
  //   handleSearch(searchQueryValue || '');
  // }, [selectedPlaylist]);

  useEffect(() => {
    catchError(error || playlistError || getPlaylistsError || searchInPlaylistError || searchVideosError);
  }, [getPlaylistsError, searchInPlaylistError, searchVideosError, error, playlistError]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {isSuccess && (
        <>
          <div style={{ display: 'flex', position: 'relative', minHeight: '100vh' }}>
            <NavBar
              playlistsList={playlists.results}
              selectedPlaylist={selectedPlaylist}
              setSelectedPlaylist={setSelectedPlaylist}
              isCollapsed={isCollapsed}
              onCollapsedChange={setIsCollapsed}
            />
            <LibraryRightSide>
              <SearchInVideoInput
                placeholder={
                  selectedPlaylist ? 'Какой фрагмент видео ищем в плейлисте?' : 'Какое видео ищем в Visaver?'
                }
                getSearch={makeSearch}
                selectedPlaylist={selectedPlaylist}
              />
              <VideoList
                isLoading={
                  isGetVideosLoading || isSearchInPlaylistLoading || isSearchVideosLoading || isPlaylistLoading
                }
                filteredVideos={filteredVideos ?? []}
                searchVideos={searchVideos}
                searchVideosInPlaylist={searchVideosInPlaylist ?? []}
                searchQueryValue={searchQueryValue}
                playlistsList={playlists.results}
                searchResult={searchResult ?? []}
                selectedPlaylistId={selectedPlaylist}
              />
            </LibraryRightSide>
          </div>
        </>
      )}
    </>
  );
};
