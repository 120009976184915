import { QuizBlock } from './components/QuizBlock';
import { QuizBlockWrapper, QuizPageStyledForm, QuizScrollBlock } from './EditQuizPage.styled';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePatchQuizzesMutation } from '@/api';
import { Button, FullScreenLoader, RemoveQuiz } from '@/components';
import { useActions, useHandlingError } from '@/hooks';
import { Buttons } from '@/pages/AdminPage/EditTimecodesPage/EditTimecodesPage.styled';
import { QuizApiResponse, QuizFormState } from '@/types';
import { parseDuration } from '@/utils';

const transformToQuizFetchedData = ({ quizzes }: QuizFormState): Pick<QuizApiResponse, 'data'> => ({
  data: quizzes.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: parseDuration(chapter.start as string) },
    quiz: quiz.map(({ question, answers }) => ({
      question,
      correctAnswer: answers.find(({ isCorrect }) => isCorrect)?.answer || '',
      wrongAnswers: answers.filter((answer) => !answer.isCorrect).map(({ answer }) => answer),
    })),
  })),
});

export const EditQuizForm = ({
  defaultValues,
  goToTime,
  videoId,
}: {
  defaultValues: QuizFormState;
  goToTime: (value: number | string) => void;
  videoId: string;
}) => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  const { playlistId } = useParams();
  const { showNotification } = useActions();
  const [updateQuiz, { isLoading: updateStatus }] = usePatchQuizzesMutation();

  const { catchError } = useHandlingError();

  const methods = useForm<QuizFormState>({ defaultValues, mode: 'onChange' });

  const handleSubmitQuiz = async (value: QuizFormState) => {
    try {
      await updateQuiz({
        videoId,
        publicId: defaultValues.publicId,
        body: transformToQuizFetchedData(value),
      }).unwrap();

      showNotification({
        text: t('save_quiz', { ns: 'success' }),
        severity: 'success',
      });
      methods.reset();
      navigate(`/videos/${videoId}`);
    } catch (err) {
      catchError(err, t('save_quiz_err', { ns: 'error' }));
    }
  };

  const { fields, remove, insert } = useFieldArray<QuizFormState, 'quizzes', 'key'>({
    name: 'quizzes',
    keyName: 'key',
    control: methods.control,
  });

  const handleAddQuestionBlock = (index: number) => {
    insert(index + 1, {
      chapter: { text: '', title: '', start: '' },
      quiz: [
        {
          question: '',
          answers: [
            { answer: '', isCorrect: false },
            { answer: '', isCorrect: false },
          ],
        },
      ],
    });
  };

  return (
    <FormProvider {...methods}>
      <QuizPageStyledForm onSubmit={methods.handleSubmit(handleSubmitQuiz)}>
        <QuizScrollBlock>
          {fields.map(({ key }, index) => (
            <QuizBlockWrapper key={key}>
              <QuizBlock
                updateStatus={updateStatus}
                removeBlock={() => remove(index)}
                handleAddQuestionBlock={() => handleAddQuestionBlock(index)}
                index={index}
                goToTime={goToTime}
              />
            </QuizBlockWrapper>
          ))}
        </QuizScrollBlock>
        {updateStatus && <FullScreenLoader />}
        {fields.length > 0 && (
          <Buttons>
            <Button theme="pink" variant="pink" onClick={() => navigate(`/videos/${videoId}`)} disabled={updateStatus}>
              Отменить
            </Button>
            <Button
              theme="colored"
              variant="colored"
              type="submit"
              disabled={updateStatus || (methods.formState.errors?.quizzes?.length || 0) > 0}
            >
              {t('admin.save')}
            </Button>
          </Buttons>
        )}
      </QuizPageStyledForm>
      <RemoveQuiz playlistId={playlistId || ''} videoId={videoId || ''} publicId={defaultValues.publicId || ''} />
    </FormProvider>
  );
};
