import styled from 'styled-components';

import { SecondaryText, theme } from '@/styles';

export const BalanceWrapper = styled.div`
  background-color: ${theme.colors.modal.purple_200};
  border-radius: 12px;
  padding: 16px;

  @media screen and (max-width: 645px) {
    width: 100%;
  }
`;

export const BalanceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 250px;

  @media screen and (max-width: 478px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const BalanceCount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const TextBalance = styled(SecondaryText)`
  font-size: 16px;
  font-weight: 500;
  color: white;
`;

export const TextPay = styled(TextBalance)<{ canProcessVideo?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.canProcessVideo ? '#4ade80' : '#f87171')};
`;

export const BalanceInfoBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #a855f71a;
  padding: 4px 8px;
  border-radius: 12px;
  margin-right: 6px;
`;

export const SecondaryTextBalance = styled(SecondaryText)`
  font-size: 14px;
  color: ${theme.colors.modal.text.gray};

  @media screen and (max-width: 478px) {
    margin-bottom: 10px;
  }
`;

export const SecondaryTextButton = styled(SecondaryText)`
  font-size: 14px;
  color: ${theme.colors.modal.purple_500};
`;
