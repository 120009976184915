import styled from 'styled-components';

import { theme, TitleH3 } from '@/styles';

type TabsSwitcherProps = {
  switchPosition?: string;
  isActive?: boolean;
};

export const Wrapper = styled.ul<TabsSwitcherProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 540px;
  margin: 0 auto;
  width: 100%;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${(props) => props.switchPosition};
    width: 33%;
    height: 2px;
    background-color: ${theme.colors.white.white_100};
    transition: all 0.3s;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const SwitchItem = styled.li<TabsSwitcherProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 10px;
  /* border-bottom: 2px solid ${theme.colors.white.white_10}; */
  cursor: pointer;

  h3 {
    color: ${(props) => (props.isActive ? theme.colors.white.white_100 : theme.colors.white.white_40)};
  }
`;

export const SwitchTitle = styled(TitleH3)<TabsSwitcherProps>`
  font-size: 17px;
`;
