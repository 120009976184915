import styled, { css } from 'styled-components';

import { IframeThemeType } from '@/types/iframeTypes';

export const Content = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  background-color: rgb(37 26 86);
  border-radius: 0.75rem;
  border: 1px solid rgba(168, 85, 247, 0.3);
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 270px;
  cursor: pointer;

  :hover {
    border-color: rgb(168 85 247);
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      background-color: rgba(60, 64, 67, 0.07);
    `}
`;

export const FragmentTimeV2 = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  color: #e9d5ff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26, 17, 69);
      margin-top: 2px;
    `}
`;
export const Time = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ShareIconWrapper = styled.div<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  display: none;
  background-color: transparent;

  :hover {
    background-color: rgba(168, 85, 247, 0.4);
  }

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      :hover {
        background-color: rgb(168 85 247);
      }
    `};
`;

export const TitleFragment = styled.span<{ iframeTheme?: IframeThemeType; isIframe?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  line-clamp: 2;
  hyphens: none;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  white-space: break-spaces; //Запрещаем перенос строк
  color: #d8b4fe;
  line-height: 1.1rem;
  display: block;

  ${({ iframeTheme, isIframe }) =>
    isIframe &&
    iframeTheme === IframeThemeType.light &&
    css`
      color: rgb(26, 17, 69);
    `}
`;

export const FrameImage = styled.img<{ isVideoPage?: boolean }>`
  width: 100%;
  border-radius: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: auto;
`;
