import { ChevronLeft, ChevronRight, Edit, LayoutList, Plus, Trash } from 'lucide-react';

import { EditPlaylist, RemovePlaylist } from '@/components';
import {
  ActionButtons,
  AddButton,
  ButtonContent,
  CollapseButton,
  ContentContainer,
  MainButton,
  PlaylistContent,
  PlaylistImage,
  PlaylistItem,
  PlaylistList,
  PlaylistName,
  PlaylistsContainer,
  PlaylistTitle,
  StyledAside,
} from '@/components/NavBar/NavBar.styled';
import { useActions } from '@/hooks';
import { PlaylistSidebarBottomButtons } from '@/pages/LibraryPageV2/LibraryPageV2.styled';
import { Playlist } from '@/types';

interface SidebarProps {
  isCollapsed: boolean;
  onCollapsedChange: (isCollapsed: boolean) => void;
  playlistsList: Playlist[];
  selectedPlaylist: string | null;
  setSelectedPlaylist: (playlistId: string | null) => void;
}

const NavBar = ({
  isCollapsed,
  onCollapsedChange,
  playlistsList,
  setSelectedPlaylist,
  selectedPlaylist,
}: SidebarProps) => {
  const { openModal } = useActions();
  return (
    <StyledAside $isCollapsed={isCollapsed}>
      <ContentContainer $isCollapsed={isCollapsed}>
        <MainButton
          $isSelected={selectedPlaylist === null}
          $isCollapsed={isCollapsed}
          onClick={() => setSelectedPlaylist(null)}
        >
          <ButtonContent $isCollapsed={isCollapsed}>
            <LayoutList size={20} />
            {!isCollapsed && (
              <span style={{ fontSize: '16px', lineHeight: '18px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                Все видео
              </span>
            )}
          </ButtonContent>
        </MainButton>

        <PlaylistsContainer>
          <PlaylistTitle $isCollapsed={isCollapsed}>Плейлисты</PlaylistTitle>
          <PlaylistList>
            {playlistsList.map((playlist) => (
              <PlaylistItem
                key={playlist.publicId}
                $isSelected={selectedPlaylist === playlist.publicId}
                $isCollapsed={isCollapsed}
              >
                <PlaylistContent $isCollapsed={isCollapsed} onClick={() => setSelectedPlaylist(playlist.publicId)}>
                  <PlaylistImage>
                    {playlist.category.image && <img src={playlist.category.image} alt={playlist.title} />}
                  </PlaylistImage>
                  {!isCollapsed && <PlaylistName>{playlist.title}</PlaylistName>}
                </PlaylistContent>
                {!isCollapsed && (
                  <ActionButtons>
                    <button
                      style={{ display: 'flex' }}
                      onClick={() => {
                        setSelectedPlaylist(playlist.publicId);
                        openModal({ active: 'EditPlayList' });
                      }}
                    >
                      <Edit style={{ alignSelf: 'center' }} size={16} />
                    </button>
                    <button
                      style={{ display: 'flex' }}
                      onClick={() => {
                        setSelectedPlaylist(playlist.publicId);
                        openModal({ active: 'RemovePlaylist' });
                      }}
                    >
                      <Trash style={{ alignSelf: 'center' }} size={16} />
                    </button>
                  </ActionButtons>
                )}
              </PlaylistItem>
            ))}
          </PlaylistList>
        </PlaylistsContainer>

        <PlaylistSidebarBottomButtons>
          <AddButton
            onClick={() => openModal({ active: 'AddPlayList' })}
            $isCollapsed={isCollapsed}
            title={isCollapsed ? 'Добавить плейлист' : undefined}
          >
            <Plus color={'white'} size={20} />
            {!isCollapsed && <span style={{ fontSize: '16px', color: 'white' }}>Добавить плейлист</span>}
          </AddButton>

          <CollapseButton
            $isCollapsed={isCollapsed}
            onClick={() => onCollapsedChange(!isCollapsed)}
            title={isCollapsed ? 'Развернуть' : 'Свернуть'}
          >
            {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
          </CollapseButton>
        </PlaylistSidebarBottomButtons>
        {selectedPlaylist && (
          <>
            <EditPlaylist id={selectedPlaylist} />
            <RemovePlaylist setSelectedPlaylist={setSelectedPlaylist} publicId={selectedPlaylist} />
          </>
        )}
      </ContentContainer>
    </StyledAside>
  );
};

export default NavBar;
