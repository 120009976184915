import { DropdownWrapper } from './VideoDropDownWrapper.styled';

import { ReactNode } from 'react';

interface VideoDropDownWrapperProps {
  children: ReactNode;
}

export const VideoDropDownWrapper = ({ children }: VideoDropDownWrapperProps) => {
  return <DropdownWrapper>{children}</DropdownWrapper>;
};
