import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

import { theme } from '@/styles';

export const DropdownContainer = styled(List)`
  right: -30px;
  top: 125%;
  margin-top: 0.5rem;
  width: 255px;
  background-color: #1a1145;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(168, 85, 247, 0.3);
  transform: translateX(-10%);
`;

export const PlaylistButton = styled(ListItemButton)`
  width: 100%;
  font-size: 14px;
  color: #d8b4fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(147, 112, 219, 0.44);
  }
`;

export const DropdownHeader = styled(ListItemText)`
  font-size: 14px;
  font-family: ${theme.fonts.geist};
  font-weight: 500;
  color: white;
`;

export const TitlePlaylistListItem = styled(ListItemText)`
  color: #d8b4fe;
`;
