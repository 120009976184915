// import { Text, TitleH3, CardsList } from '../../styles/components';

import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  background: rgb(37, 26, 86);
    
  > button {
      flex: 1;
  }

    @media screen and (max-width: 768px) {
        position: sticky;
        bottom: 0;
        z-index: 999;
        padding: 8px 0;
    }      
`;

export const Wrapper = styled.div`
  padding: 16px;
  background-color: #251a56;
  max-width: 598px;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  position: relative;
  margin: 0 auto;
    
    @media screen and (max-width: 768px) {
        padding: 8px;
        padding-bottom: 0;
    }
`;
