/* eslint-disable @typescript-eslint/ban-ts-comment */
import {VideoCardProps} from './VideoCard.props';

import DropDown from '../../Shared/DropDown';
import DropDownMenuItem from '../../Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import DotsVerticalIcon from '../../SvgIcons/DotsVerticalIcon';
import PlayIcon from '../../SvgIcons/PlayIcon';
import TrashIcon from '../../SvgIcons/TrashIcon';
import {ImageWrapper, Title} from '../PlaylistCard';

import {memo, Reducer, useReducer} from 'react';

import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

import {useLazyGetDocsQuery} from '@/api';
import {InnerCard, PlayButton, VideoCardWrapper, VideoMenu, VideoTime} from '@/components/Card';
import DiaryIcon from '@/components/SvgIcons/DairyIcon';
import NotepadIcon from '@/components/SvgIcons/NotepadIcon';
import StopWatchIcon from '@/components/SvgIcons/Stopwatch';
import {useActions} from '@/hooks';
import {IframeThemeType} from '@/types/iframeTypes';

const VideoCard = ({
  movie,
  menu = false,
  withTime = false,
  isPrivate = false,
  to,
  iframeTheme,
  isIframe,
}: VideoCardProps) => {
  const { title, thumbnailUrl, publicId } = movie;
  const { openModal } = useActions();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('components');
  const { showNotification } = useActions();
  const { hash, playlistId } = useParams();
  // const [isAuth] = useAppSelector((state) => [state.user.isAuth, state.user.user_id]);

  // playlistsAPI.useReadPrivateLinkQuery({ linkHash: hash || '' }, { skip: !hash || !isAuth });

  const [getDocs] = useLazyGetDocsQuery();

  const goToTimecodes = () => {
    navigate(`watch/${publicId}`);
  };

  const goToQuiz = () => {
    navigate(`quiz/${publicId}`);
  };

  const getSummaryHandle = async () => {
    try {
      const summaryLink = await getDocs({ playlistId: playlistId || '', videoPublicId: publicId, hash: hash }).unwrap();

      const a = document.createElement('a');
      a.href = summaryLink.pdfFile;
      a.target = '_blanc';
      a.download = `${publicId}.pdf`;

      a.click();
    } catch (e) {
      showNotification({ text: t('download_summary_error', { ns: 'error' }), severity: 'error' });
    }
  };

  const dropDownMenuItems = !isPrivate
    ? [
        <DropDownMenuItem
          key={6}
          icon={<TrashIcon />}
          text={t('menu.del_vl')}
          //@ts-ignore
          onClick={() =>
            openModal({
              active: 'RemoveVideo',
              data: {
                id: publicId,
                playlistId,
              },
            })
          }
        />,
      ]
    : [
        // <DropDownMenuItem key={1} icon={<SettingsIcon />} text={'Настройки видео'} onClick={() => openModal('EditPlayList')}/>,
        <DropDownMenuItem key={2} icon={<StopWatchIcon />} text={t('menu.timecodes')} onClick={goToTimecodes} />,
        //@ts-ignore
        <DropDownMenuItem key={3} icon={<DiaryIcon />} text={t('menu.summary')} onClick={getSummaryHandle} />,
        <DropDownMenuItem key={4} icon={<NotepadIcon />} text={t('menu.quiz')} onClick={goToQuiz} />,
        !hash && (
          <DropDownMenuItem
            key={5}
            icon={<TrashIcon />}
            text={t('menu.del_vl')}
            //@ts-ignore
            onClick={() =>
              openModal({
                active: 'RemoveVideo',
                data: {
                  id: publicId,
                  playlistId,
                },
              })
            }
          />
        ),
      ];

  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };

  return (
    <>
      <VideoCardWrapper>
        <Link
          to={to ?? `watch/${publicId}?t=${movie.startsFrom ?? 0}`}
          state={{
            pathname: location.pathname,
            fromPlaylist: location.pathname.includes('playlist') ?? false,
          }}
          style={{ cursor: 'pointer' }}
        >
          <ImageWrapper bgImage={thumbnailUrl}>
            <PlayButton>
              <PlayIcon />
            </PlayButton>
            {withTime && (
              <VideoTime>
                {new Date(movie.startsFrom ?? 0).toLocaleTimeString('ru-RU', {
                  second: '2-digit',
                  minute: '2-digit',
                })}
              </VideoTime>
            )}
          </ImageWrapper>
        </Link>
        {/* <DescriptionMenuWrapper>
          </DescriptionMenuWrapper> */}
        <InnerCard>
          <Title isIframe={isIframe} iframeTheme={iframeTheme as IframeThemeType}>
            {title.slice(0, 100)}
          </Title>

          <VideoMenu>
            {menu && !isIframe && (
              <DropDown items={dropDownMenuItems} isOpen={opened} toggleOpen={toggleHandler}>
                <DotsVerticalIcon />
              </DropDown>
            )}
          </VideoMenu>
        </InnerCard>
      </VideoCardWrapper>
    </>
  );
};

export default memo(VideoCard);
